@import "../../variables.less";

.mainchat-container {
  width: 100%;
  height: calc(100Vh - 133px);
  display: flex;
  background-color: @gray_bg3;
  padding: 0;
  font: normal normal 500 15px Poppins;
  color: @main;
  @media (max-width: 1199px) {
    font: normal normal bold 0.94vw/1.25vw Poppins;
    height: calc(100Vh - 100px);
  }
  .chat-row {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: @gray_bg3;
    padding: 0 60px;
    .link {
      font: normal normal 500 15px Poppins;
      text-decoration: underline;
      margin: 20px 0 8px 0;
      @media (max-width: 1199px) {
        font: normal normal 500 0.94vw/1.25vw Poppins;
      }
      &:hover {
        cursor: pointer;
        color: @blue_light;
      }
    }
    .chat-window {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 560px;
      background-color: @white;
      overflow: auto;
      &::-webkit-scrollbar-thumb {
        background: #232D53;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(170, 168, 168, 0.75);
      }
      &::-webkit-scrollbar {
        width: 12px;
        background: #dfdcdc;
        border-radius: 1ex;
      }
      &::-webkit-scrollbar-corner {
        background: #dfdcdc;
      }
      .chat-date {
        // padding: 10px 20px;
        padding-left: 20px;
        // border-top: 3px solid rgba(35, 45, 83, 0.15);
        &.twoborder{
          margin-top: 2px;
          border-bottom: 3px solid rgba(35, 45, 83, 0.15);
          border-top: 3px solid rgba(35, 45, 83, 0.15);
          background-color: rgba(35, 45, 83, 0.15);
        }
      }

      .chat-title-wrapper{
        display: flex;
        font: normal normal 700 18px Poppins;
        width: 100%;
        padding: 2px 50px;
        @media (max-width: 1199px) {
          font: normal normal 500 0.94vw/1.25vw Poppins;
        }
        .chat-name {
          font: normal normal 700 18px Poppins;
          width: 90%;
          margin-left: 20px;
          &.blue{
            color: @blue_light;
          }
        }
        .chat-time {
          width: 100px;
          font: normal normal 700 18px Poppins;
          &.small {
            font: normal normal 500 12px Poppins;
            color: @main;
            margin: 4px 0;
            // padding: 0px 40px;
          }
        }
      }
      .chat-text{
        font: normal normal 500 12px Poppins;
        color: @main;
        margin: 0px 150px 0px 80px;
        white-space: pre-line;
        word-break: break-word;
        overflow-wrap: break-word;
        @media (max-width: 1199px) {
          font: normal normal 500 0.94vw/1.25vw Poppins;
        }
        &.small {
          margin-top: -20px;
          // border: 1px solid grey;
        }
      }
    }
    .textarea-wrapper{
      width: 80%;
      align-self: center;
      margin: 34px 0 10px 0;
    }
    .buttonsend{
      display: flex;
      margin-bottom: 10px;
      align-self: flex-end;
      margin-right: 190px;
      &:hover{
        cursor: pointer;
      }
      img {
        @media (max-width: 1199px) {
          width: 25px;
        }
      }
      .ant-btn.ant-btn-primary.blue.chat{
        width: 117px;
        background-color: #3680F6;
        height: 31px!important;
        font-size: 15px;
      }
    }
  }
}

.aside-row {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: @white;
  font: normal normal 700 18px Poppins;
  color: @main;
  padding: 50px 20px;
  @media (max-width: 1440px) {
    font: normal normal bold 0.94vw/1.25vw Poppins;
  }
  .main-title{
    font: normal normal 700 25px Poppins;
    width: fit-content;
    border-bottom: 4px solid @orange;
  }
  .sub-title{
    margin: 20px 0 10px 0;
  }
  .name{
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 10px;
    width: 100%;
    height: 56px;
    border: 1px solid #dfdddd;
    border-radius: 12px;
    margin-top: 10px;
    @media (max-width: 1199px) {
      height: 32px;
    }
  }
}

.ant-back-top{
  position: initial;
  width: 200px;
  height: 30px;
}

.ant-input.input-textarea{
  padding: 20px 30px;
}

.ant-upload.ant-upload-select.ant-upload-select-text {
  display: flex;
  justify-content: flex-end;
}

@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;