@import '../../variables.less';

.login-page-layout {
  position: relative;
  // overflow-x: hidden;
  // overflow-y: auto;
  height: 100%;
  width: 100%;
  max-width: 1920px;

  color: @main_black;
  margin: 0 auto;
  padding: 19px 20px;

  a {
    all: unset;
  }


  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-corner {
    display: none;
  }

  a, a:hover {
    color: @main_black;
    cursor: pointer;
    .arrow-wrapper > div {
      color: @main_black;
    }
    &.green {
      color: @greenMain;
    }
  }

  .login-content-new {
    .manrope;
    font-size: 14px;
    // letter-spacing: -0.2px;
    height: 100%;
    display: flex;
    .left-part {
      height: 100%;
      width: 45%;
      flex-shrink: 1;
      padding: 69px 83px;
      @media (max-width: 1200px) {
        padding: 69px 40px;
      }
      @media (max-width: 1100px) {
        width: 100%;
      }
      @media (max-width: 440px) {
        padding: 29px 10px;
      }

      .login-form-wrapper {
        height: calc(100% - 31px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 400px;
        min-width: 360px;
        margin: 0 auto;
        @media (max-width: 440px) {
          max-width: 340px;
          min-width: 310px;
        }

        .bottom-block {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 29px;
          @media (max-width: 450px) {
            flex-wrap: wrap;
            justify-content: center;
            gap: 15px;
          }

          .link-contact-us {
            color: @greenMain;
            cursor: pointer;
          }
        }
      }

    }
    .right-part {
      height: 100%;
      width: 55%;
      max-width: 1032px;
      border-radius: 22px;
      flex-shrink: 3;
      background-image: url('../../Images/login_new/login_bg_new.jpg');
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      @media (max-width: 1100px) {
        display: none;
      }
    }
  }




}


@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;