@import 'variables.less';

input[type="password"]:-webkit-autofill,
input[type="password"]:-webkit-autofill:hover,
input[type="password"]:-webkit-autofill:focus,
input[type="password"]:-webkit-autofill:active,
input[type="text"]:-webkit-autofill,
input[type="text"]:-webkit-autofill:hover,
input[type="text"]:-webkit-autofill:focus,
input[type="text"]:-webkit-autofill:active,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background-color: white !important;
    // color: inherit !important;
}


//inputs
.ant-input, .ant-input-affix-wrapper {
  &.custom-input {
    height: 40px;
    line-height: 24px;
    border: none;
    border-bottom: 1px solid @gray4;
    border-radius: 0;
    padding: 4px 1px;
    .manrope;
    font-size: 14px !important;
    color: @main_black;
    &:focus, &:focus-visible, &:hover {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid @gray4;
    }
    &::placeholder {
      .manrope;
      font-size: 14px !important;
      line-height: 19px;
      color: @main_black;
    }
  }

  &.custom-input.ant-input-status-error.ant-input,
  &.custom-input.ant-input-affix-wrapper-status-success.ant-input-affix-wrapper,
  &.custom-input.ant-input-affix-wrapper-focused.ant-input-affix-wrapper,
  &.custom-input.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper {
    box-shadow: none;
    border-color: @gray4;
    &:focus, &:hover {
      box-shadow: none;
      border-color: @gray4;
    }
  }
}

//selects
.ant-select {
  &.custom-select-new {
    height: 44px !important;

    .ant-select-selector {
      margin-top: 8px;
      height: 32px !important;
      padding: 0;
      // padding-top: 8px;
      font-family: Manrope !important;
      font-size: 14px !important;
      border: none;
      box-shadow: none;
      border-bottom: 1px solid @gray4;
      border-radius: 0;
      &:focus, &:focus-visible, &:hover {
        border: none;
        box-shadow: none;
        border-bottom: 1px solid @gray4;
      }
    }
    .ant-select-selection-item, .ant-select-selection-placeholder {
      .manrope;
      font-size: 16px;
      font-weight: 300;
      color: @main_black !important;
      .custom-select-label {
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
      }
    }

    &.ant-select-focused .ant-select-selector {
      box-shadow: none !important;
      outline: none !important;
      border-color: @gray4 !important;
    }

    .ant-select-arrow  {
      .transition;
    }
    &.ant-select-open {
      .ant-select-arrow  {
        transform: rotate(180deg);
      }
    }
  }
}
.custom-select-label {
  // font-family: Manrope !important;
  font-size: 14px;
  line-height: 25px;
  font-weight: 300;
}
.ant-select-item-option-selected {
  background: @gray6 !important;
}
.ant-select.custom-select-new .ant-select-selection-item .custom-select-label {
  font-size: 16px;
}


//form
.ant-form {
  &.contact-form, &.login-form-new {

    .ant-form-item {
      position: relative;

      .ant-form-item-label {
        font-family: Manrope;
        position: absolute;
        top: 14px;
        left: 1px;
        z-index: 2;
        transition: all 0.2s ease;
        pointer-events: none;

        label {
          font-weight: 300;
          font-size: 16px;
        }

        &:has(.active) {
          top: -14px;
          label {
            font-size: 12px;
          }
        }

      }
      .ant-form-item-control {
        z-index: 1;
      }

      &.ant-form-item.textarea-form-item {
        margin-top: -8px;
        .ant-form-item-label {
          top: 22px;
          left: 1px;
          &:has(.active) {
            top: -0px;
            label {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;