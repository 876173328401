@import '../../../../variables.less';

.ant-modal {
  &.contact-form-modal {
    color: @main_black;
    .ant-typography {
      color: @main_black;
      .manrope;
    }

    .ant-modal-content {
      .manrope;
      padding: 52px 49px 20px;
      border-radius: 22px;
      @media (max-width: 576px) {
        padding: 32px 20px 10px;
      }

      .modal-title {
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        text-align: start;
        color: @greenMain;
        margin-bottom: 17px;
        @media (max-width: 576px) {
          font-size: 22px;
          line-height: 32px;
        }
      }
      .modal-sub-title {
        font-size: 16px;
        line-height: 24px;
      }
    }

  }
}
@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;