@import '../variables.less';

.Terms {
    padding: 60px 30px 0;
    margin: 0 auto;
    max-width: 966px;
    z-index: 0;
    position: relative;
}

.Terms li {
    list-style-type: none;
    margin: 15px 0;
}

.Terms .terms--head1, .Terms li, .Terms h2, .Terms h3, .Terms h4, .Terms p, .Terms strong, .Terms th, .Terms td {
    color: #232D53;
}

.Terms .terms--head1 {
    font: normal normal 700 60px 'Poppins', sans-serif;
    line-height: 110px;
    text-align: center;
    margin-bottom: 30px;
}

.Terms .terms--subhead {
    font: normal normal 600 24px 'Poppins', sans-serif;
    margin-bottom: 10px;
    color: #232D53;
    display: flex;
    justify-content: space-between;
}

.Terms h2, .Terms h3, .Terms h4 {
    margin: 30px 0;
    font: normal normal 700 14px/28px 'Poppins', sans-serif;
}

.Terms h2, .Terms h4 {
    font-size: 15px;
}

.Terms a.site-link {
    color: @blue_light;
    &:hover {
        color: @blue;
    }
}

.Terms li, .Terms p, .Terms strong, .Terms .site-link {
    font-size: 13px;
    line-height: 21px;
    margin: 10px 0;
}

.Terms table, .Terms th, .Terms td, .Terms table {
    border: 1px solid #232D53;
}

.Terms .first--table th {
    width: 25%;
    text-align: left;
    padding: 10px;
    vertical-align: baseline;
}

.Terms .first--table td {
    vertical-align: baseline;
}

.Terms td, .Terms th {
    padding: 10px;
}

.Terms table {
    border-spacing: 0;
}

.Terms .center-align {
    text-align: center;
}

.Terms table tr:first-child {
    vertical-align: baseline;
}

.Terms .second--table td {
    width: 33%;
    text-align: center;
}

.Terms .third--table td {
    width: 50%;
    text-align: center;
}

.Terms .third-table td {
    width: 50%;
    text-align: center;
}

.Terms .letters--indent,
.Terms .head2--indent,
.Terms .number--indent,
.Terms .letter--indent {
    padding: 0 20px 0 0;
    position: absolute;
    left: -30px;
}

.Terms table ul {
    padding-left: 60px;
}

.Terms .head2--indent {
    left: -30px;
}

.Terms h2 {
    margin: 0 0 0 30px;
}

.Terms ul, .relative {
    padding: 0 0 0 30px;
}

.Terms p .letters--indent {
    left: 0;
}

.Terms h2, .Terms h3, .Terms h4, .Terms li, .relative {
    position: relative;
}

.definition {
    display: flex;
    margin-bottom: 24px;
    font-size: 13px;
    .def-title {
        font-weight: bold;
        flex: 1;
        padding-left: 32px;
    }
    .def-text {
        flex: 2;
    }
}

@media (max-width: 900px) {
    .Terms .first--table:not(.privacy) th {
        width: auto;
        text-align: center;
    }

    .Terms .first--table:not(.privacy) td:first-child {
        width: auto;
    }

    .Terms .first--table:not(.privacy) tr {
        display: flex;
        flex-direction: column;
    }
}

@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;