@import '../../../variables.less';

.main-layout .ant-layout-header.main-header-new {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 84px;
  padding: 8px 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  background: white;
  transition: 0.75s ease-in-out;
  will-change: padding;
  .transition;

  &.animate {
    padding: 8px 30px;
    @media (max-width: 576px) {
      padding: 8px 17px;
    }
  }

  .header-content-wrapper {
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    &.desktop {
      display: flex;
    }
    &.mobile {
      display: none;
    }

    .logo-link-new {
      display: flex;
      justify-content: center;
    }

    .main-logo {
      height: 31px;
      cursor: pointer;
      .transition-03;
      &:hover {
        path {
          fill: @lightGreen;
        }
      }
    }

    .header-links-block {
      display: flex;
      gap: 15px;
      align-items: center;
      padding-left: 126px;
      @media (max-width: 1220px) {
        gap: 10px;
      }
      @media (max-width: 1026px) {
        gap: 5px;
        padding-left: 10vw;
      }
      @media (max-width: 900px) {
        padding-left: 0;
      }
      @media (max-width: 780px) {
        display: none;
      }

      .header-text-link {
        cursor: pointer;
        font-family: Manrope, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: @main_black;
        padding: 10px 20px;
        border: 1px solid transparent;
        border-radius: 100px;
        transition: border 0.7s ease;
        &:hover {
          border: 1px solid @gray5;
          border-radius: 100px;
        }
        @media (max-width: 1190px) {
          padding: 10px 10px;
        }
        @media (max-width: 1064px) {
          font-size: 12px;
        }
        @media (max-width: 926px) {
          padding: 10px 6px;
        }
      }

    }

    .header-buttons-block {
      display: flex;
      column-gap: 10px;
      &.mobile {
        display: none;
      }
      @media (max-width: 780px) {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: flex;
        }
      }
    }

  }
}
@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;