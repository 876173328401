@import "../../variables.less";

.details{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .details-row{
    width: 80%;
    height: 70px;
    display: flex;
    align-items: center;
    font: normal normal 500 18px Poppins;
    color: @main;

    .details-row-img{
      display: flex;
      justify-content: center;
      width: 6%;
      height: 25px;
    }
    .details-row-text{
      margin-left: 10px;
      width: 80px;
    }

    .details-row-input{
      display: flex;
      width: 90%;
    }
  }
}

.greywrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1250px;
  height: 160px;
  border: 1px solid #D3CFCF;
  border-radius: 15px;
  margin-top: 18px;

  .content-title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 50px;
    text-decoration: underline;
    font: normal normal 500 15px Poppins;
    color: @main;
  }

  .collapse-checkbox-timeline{
    .timeline-checkboxes{
      width: 200px;
      font: normal normal 400 12px Poppins;
      color: @main;
      &.greyout {
        color: @gray_divider;
      }
    }
  }
  .timeline-date{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100px;
    font: normal normal 500 14px Poppins;
    color: @main;
    align-self: flex-start;
  }
  .timeline-startdate-text{
    font: normal normal 500 14px Poppins;
    color: @main;
  }
  .remuneration-input-wrapper{
    display: flex;
    width: 60%;
    font: normal normal 500 14px Poppins;
    color: @main;
    .input-block{
      width: 135px;
    }
    .input-description-block{
      width: 80%;
      height: 100%;
      margin-left: 10px;
      .text{
        display: flex;
        align-items: center;
        height: 44px;
        font: normal normal 400 8px Poppins;
        color: @main;
      }
    }
  }
}

.edit-agreement{
  font: normal normal 700 21px Poppins;
  color: @main;
  align-self: flex-end;
  margin-top: -40px;
  margin-right: 3%;
  align-items: center;
  &:hover{
    cursor: pointer;
  }
}

.agreement-review-title{
  font: normal normal 500 19px Poppins;
  color: @main;
  text-align: center;
  width: 100%;
}

.agreement-wrapper{
  width: 100%;
  height: 514px;
  border: 1px solid @gray_divider;
  padding: 10px;
  border-radius: 20px;
  margin-top: 40px;
  .agreement-text{
    width: 100%;
    height: 100%;
  }
}

.terms-conditions-bottom {
  &.appendix {
    span {
      font: normal normal 500 12px/16px Poppins!important;
    }
  }
}

.downloadPDF{
  margin-top: 24px;
  &:hover{
    cursor: pointer;
  }
}

.iframePdf{
  width: 100%;
  height: 100%;
}

.error {
.ant-checkbox-inner {
    border-color: #c63009!important;
    -webkit-box-shadow: -1px -1px 12px -2px rgba(208, 108, 98, 0.2);
    -moz-box-shadow: -1px -1px 12px -2px rgba(208, 108, 98, 0.2);
    box-shadow: -1px -1px 12px -2px rgba(208, 108, 98, 0.2);
  }
}


@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;