@import '../../variables.less';

:root {
  --scale-factor-1: 1; /* for lines on background */
  --scale-factor-2: 1; /* for lines on get in touch block */
}

.button-with-arrow-wrapper {
  width: 44px;
  height: 44px;
  border-radius: 100px;
  border: 1px solid @lightBlue2;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: absolute;
  top: 30px;
  left: 32px;
  will-change: width, color, justify-content, padding;
  .transition;
    > div {
    .manrope;
    color: @greenMain;
    font-size: 14px;
  }
  .arrow {
    transform: rotate(45deg);
  }

  &.arrow-sector {
    padding: 0;
    border-color: @white;
    position: absolute;
    top: 30px;
    left: 32px;
    .arrow {
      transform: rotate(0deg);
      path {
        fill: @white;
      }
    }
  }

  &.arrow-sector-page {
    &.left {
      .arrow {
        transform: rotate(-135deg);
      }
    }
    .arrow {
      path {
        fill: @white;
      }
    }
  }

  &.sectors-new {
    width: 187px;
    justify-content: space-between;
    padding: 10px 20px;
    border: 1px solid @lightBlue2;
    &:hover {
      border: 1px solid @lightGreen;
    }
    @media (max-width: 576px) {
      width: 100%;
      max-width: 340px;
      min-width: 136px;
    }
  }

  &.track-record {
    width: 187px;
    justify-content: space-between;
    padding: 10px 20px;
    border: 1px solid @lightGreen;
    > div {
      color: @white;
    }
    .arrow {
      path {
        fill: @white;
      }
    }
    &:hover {
      border: 1px solid @white;
    }
  }

  &.arrow-slider-unique-block {
    border: 1px solid @lightGreen;
    &:hover {
      border-color: @white;
    }
    &:first-child {
      transform: rotate(180deg);
    }
    path {
      fill: @white;
    }
  }
  &.arrow-collapse {
    border: 1px solid @lightGreen;
    transform: rotate(90deg);
    &:hover {
      border-color: @white;
    }
    &.open {
      transform: rotate(-90deg);
    }
    path {
      fill: @white;
    }
    @media (max-width: 700px) {
      margin-top: 40px;
    }
  }

}

@media (max-width: 1330px) {
  .ignore-br-1330 {
    display: none;
  }
}

.main-page-layout {
  position: relative;
  // overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  color: @main_black;
  // background: orange;

  &::before, &::after {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg) scale(var(--scale-factor-1));
    border-radius: 158px;
    // transition: transform 3s ease;
    .transition-3;
    z-index: 0;
    width: 1963.98px;
    height: 1793.22px;
  }
  &::after {
    top: 3050px;
    left: 25%;
    border: 1px solid @gray6;
    @media (max-width: 576px) {
      top: 3020px;
    }
  }

  &::before {
    border: 1px solid @gray6;
  }

  &.companies-page {
    &::after {
      display: none;
    }
    &::before {
      top: 1120px;
    }
  }
  &.policy-page {
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
  &.investors-page, &.sectors-page, &.track-record-page {
    &::after {
      display: none;
    }
  }

  a {
    all: unset;
  }


  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-corner {
    display: none;
  }

  a, a:hover {
    .arrow-wrapper > div {
      color: @main_black;
    }
  }

  .main-content-new {
    overflow: hidden;
    padding: 0px 28px 10px;
    z-index: 1;
    @media (max-width: 576px) {
      padding: 0px 17px 10px;
    }

    .text-orange {
      color: @main_orange;
    }

    .link {
      cursor: pointer;
    }

    .white {
      color: @white !important;
    }

    .font-28 {
      font-size: 28px;
    }

    .title {
      .manrope;
      font-weight: 300;
      line-height: 70px;
      margin: 0;
      z-index: 1;
      &.font-60 {
        font-size: 60px;
        @media (max-width: 780px) {
          font-size: 50px;
          line-height: 60px;
        }
        @media (max-width: 630px) {
          font-size: 40px;
          line-height: 50px;
        }
      }
      &.track-record-top-title, &.sectors-top-title {
        @media (max-width: 576px) {
          font-size: 28px;
          line-height: 36px;
        }
      }
      &.get-in-touch.font-60 {
        @media (max-width: 1100px) {
          font-size: 50px;
          line-height: 60px;
        }
        @media (max-width: 900px) {
          font-size: 40px;
          line-height: 50px;
        }
        @media (max-width: 750px) {
          font-size: 30px;
          line-height: 40px;
        }
        @media (max-width: 576px) {
          font-size: 28px;
          line-height: 36px;
        }
      }
      &.sub-title {
        margin-top: 8px !important;
        line-height: 36px;
      }
    }

    .main-top-block {
      position: relative;
      height: 800px;
      width: 100%;
      border-radius: 22px;
      @media (max-width: 576px) {
        border-radius: 16px;
        // background-image: url('../../Images/landing_new/mobile/main_bg_mob.jpg');
        // background-size: cover;
        // background-repeat:no-repeat;
        // background-position: center;
        .title:not(.track-record-top-title, .sectors-top-title) {
          display: none;
        }
        .sub-title {
          text-align: center;
          font-size: 22px;
        }
      }
      &.track-record, &.sectors-page {
        @media (max-width: 630px) {
          height: 500px;
        }
      }
      &.image {
        &.sectors-page {
          background-image: url('../../Images/landing_new/sectors_bg.jpg');
        }
        &.track-record {
          background-image: url('../../Images/landing_new/track_bg.jpg');
        }
        background-size: cover;
        background-repeat:no-repeat;
        background-position: center;
      }
      @media (max-width: 576px) {
        height: 343px;
        border-radius: 16px;
      }

      .video-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 22px;
        object-fit: cover;
        z-index: 1;
        @media (max-width: 576px) {
          border-radius: 16px;
        }
      }

      .gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(28, 76, 93, 0) 0%, rgba(28, 76, 93, 0.5) 100%);
        border-radius: 22px;
        z-index: 2;
        @media (max-width: 576px) {
          border-radius: 16px;
        }
      }

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(250, 249, 249, 1);
        will-change: height, border-radius;
        transition: height 0.9s;
        transition-timing-function: cubic-bezier(0.77, 0, 0.16, 1);
        z-index: 2;
        border-radius: 21px;
        @media (max-width: 576px) {
          display: none;
        }
      }

      &.animate .mask {
        border-radius: 21px 21px 0 0;
        height: 0;
      }

      .main-top-title-block {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 0 60px 62px;
        z-index: 3;
        @media (max-width: 630px) {
          padding: 19px 10px 19px 25px;
        }
        @media (max-width: 576px) {
          &:not(:has(.track-record-top-title, .sectors-top-title)) {
            padding: 0 39px;
            top: 50%;
            transform: translateY(-50%);
            bottom: auto;
          }
        }
      }
    }

    .block-title {
      .manrope;
      font-size: 14px;
      line-height: 22px;
      width: fit-content;
      font-weight: 500;
      color: @greenMain;
      padding: 10px 20px;
      border: 1px solid @main_orange;
      border-radius: 100px;
      transition: border 0.7s ease;
      margin: 170px auto 0;
      z-index: 1;
      @media (max-width: 768px) {
        margin: 120px auto 0;
      }
      @media (max-width: 576px) {
        margin: 42px auto 0;
        padding: 6px 20px;
      }
      &.testimonial {
        margin: 0px 0 0;
      }
      &.get-in-touch {
        color: @white;
        margin: 0px auto auto;
      }

      &.unique {
        margin: 55px 0 0;
        @media (max-width: 576px) {
          margin: 25px 0 0;
        }
      }
      &.secondary-top-block {
        margin: 0;
        @media (max-width: 650px) {
          // fs
        }
      }
      &.case-study {
        margin: 0;
      }
    }

    .block-text {
      .manrope;
      &.testimonial {
        font-size: 28px;
        line-height: 36px;
        font-weight: 300;
        text-align: center;
        color: @greenMain;
        margin-top: 42px;
        &.main-page {
          font-size: 42px;
          line-height: 52px;
          @media (max-width: 750px) {
            font-size: 36px;
            line-height: 44px;
          }
          @media (max-width: 576px) {
            font-size: 22px;
            line-height: 30px;
          }
        }
        @media (max-width: 576px) {
          font-size: 22px;
          line-height: 30px;
        }
        &.investors-page {
          width: 90%;
          max-width: 1130px;
        }
        &.track-record, &.sectors-page {
          font-size: 42px;
          line-height: 52px;
          @media (max-width: 850px) {
            font-size: 36px;
            line-height: 44px;
          }
          @media (max-width: 576px) {
            font-size: 22px;
            line-height: 30px;
          }
        }
      }
      &.name {
        font-size: 22px;
        color: @greenMain;
        margin-top: 23px;
        line-height: 30px;
      }
      &.role {
        font-size: 16px;
        margin: 5px 0 14px;
        color: #4F4F4F;
        max-width: 400px;
        text-align: center;
      }
    }

    .animated-block {
      opacity: 0;
      transform: translateY(50px);
      transition: opacity 0.3s ease-in, transform 0.3s ease-in;
    }

    .animated-block.visible {
      opacity: 1;
      transform: translateY(0);
    }

    .main-about-us-block, .main-our-services-block, .what-we-offer-block {
      // margin-top: 168px;
      position: relative;
      z-index: 1;

      .title {
        .manrope;
        color: @greenMain;
        font-size: 60px;
        line-height: 70px;
        font-weight: 300;
        margin: 40px auto 104px;
        text-align: center;
        width: 78%;
        &.investors-page {
          width: 80%;
          max-width: 1180px;
          font-size: 42px;
          line-height: 52px;
          @media (max-width: 768px) {
            font-size: 40px;
            line-height: 56px;
            width: 100%;
            margin: 40px auto 53px;
          }
          @media (max-width: 576px) {
            font-size: 28px;
            line-height: 36px;
          }
        }
        @media (max-width: 768px) {
          font-size: 40px;
          line-height: 56px;
          width: 100%;
          margin: 24px auto 53px;
        }
        @media (max-width: 576px) {
          font-size: 28px;
          line-height: 36px;
        }
      }
      .text {
        .manrope;
        font-size: 22px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.02em;
        display: block;
        @media (max-width: 576px) {
          font-size: 18px;
          line-height: 28px;
        }
        &.center {
          text-align: center;
        }
      }
    }

    .main-our-services-block {
      width: 100%;
      overflow: hidden;
      z-index: 1;
      .title {
        font-size: 35px;
        line-height: 45px;
        width: 70%;
        margin: 40px auto 50px;
        @media (max-width: 576px) {
          font-size: 18px;
          line-height: 28px;
          width: 96%;
        }
      }

      .service-block-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        width: fit-content;
        margin: 50px auto;
        min-height: 400px;
        // &.desktop {
        //   display: flex;
        // }
        // &.mobile {
        //   display: none;
        // }

        // @media (max-width: 576px) {
        //   &.desktop {
        //     display: none;
        //   }
        //   &.mobile {
        //     display: block;
        //     border: 1px solid red;
        //     .carousel-item {
        //       width: 300px;
        //       height: 500px;
        //       border: 1px solid blue;
        //     }
        //   }
        // }

        .service-block-item {
          position: relative;
          height: 320px;
          width: 318px;
          padding: 30px 32px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          row-gap: 14px;
          border-radius: 22px;
          background: @lightBlue;
          will-change: height, background, color;
          .transition;
          @media (max-width: 576px) {
            border-radius: 16px;
          }

          .arrow-wrapper {
            width: 44px;
            height: 44px;
            border-radius: 100px;
            border: 1px solid @lightBlue2;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 30px;
            left: 32px;
            will-change: width, color, justify-content, padding;
            .transition;
            &:hover {
              border-color: @white;
            }
          }

          .text {
            text-align: start;
            // width: 50%;
            font-size: 30px;
            font-weight: 300;
            line-height: 40px;
            transition: color 0.3s ease;
          }

          .sub-text, .learn-more {
            display: none;
            font-family: Manrope, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: @white;
            opacity: 0;
            will-change: display, opacity, content;
            .transition;

          }
          .learn-more {
            white-space: nowrap;
            font-size: 14px;
          }
          // svg.icon {
          //   // .transition;
          // }

          &:hover {
            height: 429px;
            background: @greenMain;

            svg.icon {
              path {
                fill: @white;
              }
              // transform: rotate(45deg);
            }
            .text {
              color: @white;
            }
            .arrow-wrapper {
              border: 1px solid @lightGreen;
              justify-content: space-between;
              width: 187px;
              padding: 0 16px;
              cursor: pointer;
              &:hover {
                border-color: @white;
              }
              .arrow {
                path {
                  fill: @white;
                }
                fill: @white;
                transform: rotate(45deg);
              }
            }
            .sub-text, .learn-more {
              display: block;
              opacity: 1;
            }
          }

        }
      }
    }

    .main-unique-block {
      z-index: 1;
      height: 900px;
      background: @greenMain;
      border-radius: 22px;
      position: relative;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      @media (max-width: 992px) {
        height: auto;
        padding: 23px;
        justify-content: center;
      }
      @media (max-width: 576px) {
        border-radius: 16px;
      }

      .mobile-flex-container {
        display: flex;
        align-items: center;
        @media (max-width: 992px) {
          flex-direction: column;
        }
      }

      .left-square-bg {
        background: #1E5366;
        border-radius: 0 22px 22px 0;
        // position: absolute;
        // left: 0;
        // top: 50%;
        // transform: translateY(-50%);
        height: 666px;
        width: 191px;
        @media (max-width: 1300px) {
          width: 3vw;
        }
        @media (max-width: 992px) {
          position: absolute;
          left: 0;
          top: 23px;
          width: 5vw;
        }
        @media (max-width: 576px) {
          height: 300px;
          width: 10px;
        }
      }
      .right-square-bg {
        background: #1E5366;
        border-radius: 22px 0 0 22px;
        height: 666px;
        display: none;
        @media (max-width: 1300px) {
          width: 3vw;
        }
        @media (max-width: 992px) {
          display: block;
          position: absolute;
          right: 0;
          top: 23px;
          width: 5vw;
        }
        @media (max-width: 576px) {
          height: 300px;
          width: 10px;
        }
      }
      .slider-img-wrapper {
        height: 666px;
        max-width: 666px;
        margin-left: 60px;
        overflow: hidden;
        border-radius: 23px;
        z-index: 1;
        @media (max-width: 1300px) {
          margin-left: 3vw;
        }
        @media (max-width: 992px) {
          margin-left: 0;
        }
        @media (max-width: 821px) {
          max-width: 70vw;
        }
        @media (max-width: 576px) {
          height: 300px;
          max-width: 100%;
        }

        > img {
          height: 100%;
          object-fit: cover;
          .transition;
        }
      }
      .content-wrapper {
        height: 666px;
        max-width: 550px;
        margin-left: 146px;
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        @media (max-width: 1660px) {
          margin-left: 5vw;
        }
        @media (max-width: 1300px) {
          margin-left: 3vw;
          max-width: 450px;
        }
        @media (max-width: 992px) {
          align-self: flex-start;
          margin-left: 0;
        }
        @media (max-width: 576px) {
          padding-top: 30px;
          height: auto;
          padding-bottom: 10px;
        }

        .arrow-slider-unique-block-wrapper {
          display: flex;
          gap: 14px;
          @media (max-width: 576px) {
            display: none;
          }
        }

        .content-title {
          .manrope;
          font-size: 54px;
          line-height: 60px;
          font-weight: 300;
          letter-spacing: -0.02em;
          margin-top: 24px;
          @media (max-width: 576px) {
            font-size: 28px;
            line-height: 36px;
          }
        }
        .content-text {
          .manrope;
          font-size: 22px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0.02em;
          margin-top: 34px;
          min-height: 270px;
          @media (max-width: 576px) {
            font-size: 16px;
            line-height: 24px;
            min-height: 290px;
          }
        }

        .custom-dots-wrapper {
          margin-top: 20px;
          display: flex;
          align-items: center;

          .click-wrapper {
            height: 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
          .custom-dot {
            border: 1px solid @lightGreen;
            background: @lightGreen;
            border-radius: 12px;
            width: 40px;
            height: 2px;
            margin-right: 10px;
            cursor: pointer;
            &.active {
              border-color: @white;
              background: @white;
            }
          }
        }
        .counter {
          .manrope;
          font-size: 22px;
          font-weight: 300;
          line-height: 30px;
          letter-spacing: 0.02em;
          color: @lightGreen;
          margin-left: 12px;
        }
      }
    }

    .main-sectors-block {
      z-index: 1;
      height: 900px;
      background: @lightBlue;
      border-radius: 22px;
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 100px;
      position: relative;
      @media (max-width: 992px) {
        padding: 0;
        background: transparent;
        height: auto;
      }
      @media (max-width: 576px) {
        border-radius: 16px;
      }

      .content-wrapper {
        width: 100%;
        max-width: 1360px;
        // height: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .title {
        .manrope;
        font-size: 60px;
        font-weight: 300;
        line-height: 60px;
        letter-spacing: -0.02em;
        @media (max-width: 576px) {
          font-size: 28px;
        }
      }

      .sectors-img-wrapper {
        display: flex;
        column-gap: 27px;
        margin-top: 47px;
        width: 100%;
        max-width: 1360px;
        overflow-x: scroll;
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-corner {
          display: none;
        }
        @media (max-width: 576px) {
          column-gap: 10px;
          margin-right: -17px;
          width: 105%;
        }

        .sector-item {
          width: 319px;
          min-width: 319px;
          // height: 480px;
          // border-radius: 22px;
          position: relative;
          // cursor: pointer;
          > img {
            border-radius: 16px;
          }
          .img-mask {
            position: absolute;
            border-radius: 18px;
            bottom: 0;
            height: 100%;
            // background: @greenMain;
            // background: linear-gradient(180deg, rgba(28, 76, 93, 0) 0%, rgba(28, 76, 93, 1) 100%);
            background:
                        linear-gradient(0deg, #1C4C5D 1.6%, rgba(28, 76, 93, 0.2) 100%),
                        linear-gradient(0deg, #1C4C5D 1.6%, rgba(28, 76, 93, 0.2) 100%);
            width: 100%;
            opacity: 0;
            will-change: opacity;
            .transition-05;
          }

          .sector-title {
            .manrope;
            font-size: 30px;
            font-weight: 300;
            line-height: 40px;
            position: absolute;
            bottom: 30px;
            left: 32px;
            will-change: bottom;
            .transition-05;
          }
          // .arrow-wrapper-learn-more {
          //   display: none;
          //   position: absolute;
          //   bottom: 30px;
          //   left: 32px;
          //   border-radius: 100px;
          //   border: 1px solid @greenBorder;
          //   justify-content: space-between;
          //   align-items: center;
          //   padding: 10px 20px;
          //   white-space: nowrap;
          //   cursor: pointer;
          //   .transition-03;
          //   .learn-more {
          //     .manrope;
          //     font-size: 14px;
          //   }
          // }
          .sub-sectors-wrapper {
            position: absolute;
            bottom: 30px;
            left: 32px;
            display: flex;
            flex-direction: column;
            gap: 23px;
            opacity: 0;
            will-change: opacity;
            .transition-05;

            .sub-sector {
              .manrope;
              display: flex;
              gap: 19px;
              font-size: 16px;
              line-height: 24px;
              color: @white;
            }
          }

          &:hover {

            .img-mask {
              opacity: 1;
            }
            .sector-title {
              bottom: 237px;
            }
            .sub-sectors-wrapper {
              opacity: 1;
            }
            // .arrow-wrapper-learn-more {
            //   display: flex;
            //   column-gap: 50px;
            //   .arrow {
            //     .transition;
            //   }
            //   &:hover {
            //     border-color: @white;
            //     .arrow {
            //       .transition;
            //       transform: rotate(45deg);
            //     }
            //   }
            //   .arrow {
            //     path {
            //       fill: @white;
            //     }
            //   }
            // }
            .arrow-sector{
              border-color: @main_orange;
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    .testimonial-block {
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 1360px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 183px auto;
      &.track-record {
        max-width: 1223px;
        @media (max-width: 850px) {
          margin: 59px auto 41px;
        }
      }
      @media (max-width: 576px) {
        margin: 59px auto 41px;
      }

      .testimonial-img {
        margin-top: 60px;
        @media (max-width: 576px) {
          margin-top: 52px;
        }
      }
    }

    .main-get-in-touch-block {
      position: relative;
      height: 623px;
      background: @greenMain;
      border-radius: 22px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: @greenMain;
      overflow: hidden;
      contain: layout;
      z-index: 2;
      padding: 100px 0 83px;
      @media (max-width: 1100px) {
        height: 523px;
      }
      @media (max-width: 750px) {
        height: 500px;
        padding: 42px 10px;
      }
      @media (max-width: 576px) {
        border-radius: 16px;
      }

      &::before, &::after, .third-square {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg) scale(var(--scale-factor-2));
        border-radius: 60px;
        // transition: transform 3s ease;
        will-change: transform;
        .transition-1;
        z-index: 0;
        @media (max-width: 750px) {
          border-radius: 20px;
        }
      }

      &::before {
        width: 548px;
        height: 548px;
        border: 1px solid @lightGreen;
        @media (max-width: 750px) {
          height: 248px;
          width: 248px;
        }
      }

      &::after {
        width: 898.03px;
        height: 898.03px;
        border: 1px solid @lightGreen;
        @media (max-width: 750px) {
          height: 548px;
          width: 548px;
        }
      }

      .third-square {
        width: 1250.17px;
        height: 1250.17px;
        border: 1px solid @lightGreen;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg) scale(var(--scale-factor-2));
        @media (max-width: 750px) {
          height: 748px;
          width: 748px;
        }
      }


      .contact-us-wrapper {
        height: 60px;
        width: 260px;
        border-radius: 100px;
        background: @white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        margin-top: auto;
        font-weight: 500;
        cursor: pointer;
        z-index: 2;
        .arrow {
          .transition-03;
        }
        &:hover {
          .arrow {
            transform: rotate(45deg);
          }
        }
        @media (max-width: 750px) {
          // margin-top: auto;
        }
      }
    }

    .footer-wrapper {
      height: 130px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .adress {
        .manrope;
        font-size: 12px !important;
        color: @gray3;
        @media (max-width: 800px) {
          display: none;
        }

      }

      .hide-ltd {
        @media (max-width: 1056px) {
          display: none;
        }
      }
      .follow-us {
        @media (max-width: 800px) {
          display: none;
        }
      }


      .footer-first-row-wrapper {
        width: 100%;
        max-width: 1360px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 3;
        .link-linkedin-mobile {
          display: none;
        }

        @media (max-width: 780px) {
          flex-direction: column;
          margin: 36px auto 0;
          height: 100px;

          .icon-linked {
            display: none;
          }
          .link-linkedin-mobile {
            display: block;
          }
        }

        .footer-block {
          display: flex;
          column-gap: 26px;
          &.right {
            margin-left: 200px;
            @media (max-width: 1180px) {
              margin-left: 2vw;
            }
          }

          @media (max-width: 780px) {
            &.rights {
              order: 0;
              width: 100%;
              .footer-text {
                display: flex;
                width: 100%;
                justify-content: space-between;
              }
            }
            &.links {
              order: 2;
            }
            // &.get-in-touch {
            //   order: 1;
            // }
            &.get-in-touch {
              width: 100%;
              justify-content: space-between;
            }
            &.links {
              width: 100%;
              justify-content: space-between;
            }
          }

          .footer-text {
            .manrope;
            font-weight: 500;
            line-height: 34px;
            font-size: 14px;
            height: 34px;
            &.fw-300 {
              font-weight: 300;
            }
            &.link {
              cursor: pointer;
              position: relative;
              > a {
                .manrope !important;
                font-size: 14px;
                color: @main_black;

                text-decoration: none;

                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  width: 100%;
                  bottom: 0px;
                  border-bottom: 1px solid transparent;
                  transform: scaleX(0);
                  transform-origin: center left;
                  transition: transform 0.5s ease, border-color 0.5s ease;
                }
              }
              > a:hover {
                &::before {
                  transform: scaleX(1);
                  border-color: @main_orange;
                }
              }
              > a:not(:hover)::before {
                transform: scaleX(0);
                transform-origin: right;
              }
            }
          }
          svg.icon-linked {
            cursor: pointer;
            &:hover {
              rect {
                .transition-03;
                fill: @lightGreen;
              }
            }
          }
        }
      }
    }


    //secondary pages
    .secondary-page-top-block {
      z-index: 1;
      height: 800px;
      background: @greenMain;
      border-radius: 22px;
      position: relative;
      display: flex;
      align-items: center;
      position: relative;
      // justify-content: space-between;
      @media (max-width: 992px) {
        flex-direction: column;
        height: auto;
        padding: 23px 22px;
        padding-bottom: 40px;
        row-gap: 47px;
      }
      @media (max-width: 576px) {
        border-radius: 16px;
      }

      .square-bg {
        background: #1E5366;
        border-radius: 0 22px 22px 0;
        height: 666px;
        width: 191px;
        @media (max-width: 1700px) {
          width: 5vw;
        }
        @media (max-width: 992px) {
          position: absolute;
          top: 23px;
          left: 0;
        }
        @media (max-width: 576px) {
          height: 300px;
          width: 10px;
          border-radius: 0 12px 12px 0;
        }
        &.right {
          border-radius: 22px 0 0 22px;
          margin-left: auto;
          @media (max-width: 992px) {
            position: absolute;
            top: 23px;
            right: 0;
          }
          @media (max-width: 576px) {
            height: 300px;
            border-radius: 12px 0 0 12px;
          }
        }

      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        will-change: height;
        transition: height 0.9s;
        transition-timing-function: cubic-bezier(0.77, 0, 0.16, 1);
        z-index: 2;
      }

      &.animate_bg .mask {
        height: 0;
      }

      .img-wrapper {
        height: 666px;
        max-width: 666px;
        margin-left: 60px;
        overflow: hidden;
        border-radius: 23px;
        z-index: 1;
        position: relative;
        @media (max-width: 1200px) {
          margin-left: 30px;
        }
        @media (max-width: 992px) {
          margin-left: 0px;
          width: 86%;
        }
        @media (max-width: 576px) {
          width: 100%;
          height: 300px;
          max-width: 300px;
          border-radius: 12px;
        }

        > img {
          height: 100%;
          object-fit: cover;
          .transition;
        }

        .mask_img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: @greenMain;
          will-change: height;
          transition: height 0.9s;
          transition-timing-function: cubic-bezier(0.77, 0, 0.16, 1);
          z-index: 2;
          border-radius: 23px;
        }
        &.animate_img .mask_img {
          border-radius: 0px;
          height: 0;
        }
      }

      .content-wrapper {
        height: 666px;
        // min-width: 300px;
        max-width: 600px;
        margin-left: 146px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 1700px) {
          margin-left: 5vw;
        }
        @media (max-width: 1300px) {
          margin-left: 3vw;
          max-width: 500px;
        }
        @media (max-width: 1150px) {
          margin-left: 2vw;
          max-width: 450px;
        }
        @media (max-width: 992px) {
          max-width: 100%;
          height: auto;
        }

        .arrow-slider-unique-block-wrapper {
          display: flex;
          gap: 14px;
        }

        .content-title {
          .manrope;
          font-size: 54px;
          font-weight: 300;
          line-height: 60px;
          letter-spacing: -0.02em;
          margin-top: 24px;
          @media (max-width: 576px) {
            margin-top: 18px;
          }
          &.top-block {
            line-height: 70px;
            @media (max-width: 650px) {
              line-height: 50px;
            }
            @media (max-width: 576px) {
              line-height: 36px;
            }
          }
          &.fs-60 {
            font-size: 60px;
            @media (max-width: 650px) {
              font-size: 40px;
            }
            @media (max-width: 576px) {
              font-size: 28px;
            }
          }
          &.fs-54 {
            font-size: 54px;
            @media (max-width: 650px) {
              font-size: 40px;
            }
            @media (max-width: 576px) {
              font-size: 28px;
            }
          }
        }
        .content-text {
          .manrope;
          font-size: 22px;
          line-height: 30px;
          font-weight: 300;
          letter-spacing: 0.02em;
          margin-top: 34px;
          // min-height: 280px;
          @media (max-width: 576px) {
            font-size: 16px;
            line-height: 24px;
            margin-top: 22px;
          }
        }

      }
    }

    .case-study-block {
      z-index: 1;
      height: 900px;
      background: @lightBlue;
      border-radius: 22px;
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 100px;
      position: relative;
      @media (max-width: 1500px) {
        height: auto;
        padding: 112px 50px;
      }
      @media (max-width: 992px) {
        padding: 50px;
      }
      @media (max-width: 576px) {
        padding: 23px 17px;
        margin-left: -17px;
        margin-right: -17px;
        border-radius: 16px;
      }

      .img-case-study {
        width: 435px;
        @media (max-width: 992px) {
          width: 100%;
          height: 600px;
          object-fit: cover;
          border-radius: 22px;
        }
        &.mobile {
          display: none;
          width: 100%;
          height: 300px;
          margin: 40px auto 0;
        }
        @media (max-width: 576px) {
          &.desktop {
            display: none;
          }
          &.mobile {
            display: block;
          }
          border-radius: 16px;
        }
      }

      &.investors-page {
        height: auto;
        padding-bottom: 76px;
        display: none;
        &.open {
          display: block;
        }

        .title {
          text-align: left;
          margin: 0;
          width: 100%;
        }
        @media (max-width: 576px) {
          .img-case-study {
            height: 300px;
            width: 100%;
          }
        }
      }

      .content-wrapper {
        .manrope;
        width: 100%;
        max-width: 1360px;
        margin: 0 auto;
        display: flex;
        gap: 147px;
        @media (max-width: 1500px) {
          gap: 100px;
        }
        @media (max-width: 1300px) {
          gap: 70px;
        }
        @media (max-width: 1200px) {
          gap: 50px;
        }
        @media (max-width: 992px) {
          flex-direction: column;
        }

        .info-wrapper {
          display: flex;
          flex-direction: column;
          // max-height: 757px;
          // overflow-y: auto;
          padding-right: 6px;
          justify-content: center;
          &::-webkit-scrollbar-thumb {
            background: @greenMain;
          }
          &::-webkit-scrollbar {
            width: 4px;
            border-radius: 1ex;
          }
        }
      }

      .title {
        .manrope;
        font-size: 54px;
        line-height: 60px;
        font-weight: 300;
        letter-spacing: -0.02em;
        // margin-top: 24px;
        color: @greenMain;

        @media (max-width: 850px) {
          font-size: 40px;
          line-height: 50px;
        }
        @media (max-width: 670px) {
          font-size: 28px;
          line-height: 36px;
        }
        &.sub {
          font-size: 22px;
          line-height: 22px;
        }
      }

      .sub-title-wrapper {
        display: flex;
        gap: 10px;
      }

      .text-1 {
        margin-top: 44px;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        @media (max-width: 576px) {
          margin-top: 19px;
        }
      }

      .approach-outcome-block {
        display: flex;
        gap: 41px;
        margin-top: 39px;
        @media (max-width: 576px) {
          flex-direction: column;
        }

        .left-part {
          flex: 1;
          width: 50%;
          @media (max-width: 576px) {
            width: 100%;
          }
        }
        .right-part {
          flex: 1;
          width: 50%;
          @media (max-width: 576px) {
            width: 100%;
          }
        }
        .text-wrapper {
          margin-top: 28px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .bottom-link-wrapper {
        margin-top: 54px;
        display: flex;
        align-items: center;
        gap: 20px;
        @media (max-width: 576px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

    }

    .collapse-header {
      height: 220px;
      background: @greenMain;
      padding: 0 51px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 22px;
      margin: 15px auto 15px;
      max-width: 1362px;
      z-index: 1;
      position: relative;
      @media (max-width: 576px) {
        border-radius: 16px;
      }
      .title {
        margin: 0;
        font-size: 54px;
        width: fit-content;
        text-align: start;
        @media (max-width: 840px) {
          font-size: 40px;
          line-height: 50px;
        }
        @media (max-width: 700px) {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 10px;
        }
      }
      @media (max-width: 700px) {
        flex-direction: column;
        height: auto;
        max-width: 80%;
        min-width: 343px;
        padding: 28px 31px;
        align-items: flex-start;
      }
      @media (max-width: 576px) {
        max-width: 100%;
      }
      @media (max-width: 370px) {
        min-width: 330px;
      }
      .text {
        max-width: 620px;
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        @media (max-width: 1200px) {
          max-width: 50%;
        }
        @media (max-width: 992px) {
          max-width: 40%;
        }
        @media (max-width: 700px) {
          max-width: 100%;
        }
      }
    }
    .collapse-content {
      height: 0;
      width: 100%;
      background: @lightBlue;
      border-radius: 22px;
      margin-bottom: 30px;
      margin-top: 0px;
      z-index: 0;
      padding-top: 0;
      will-change: height, margin-top, padding-top;
      .transition-05;

      .testimonial-block {
        display: none;
      }
      &.open {
        height: auto;
        position: relative;
        margin-top: -150px;
        padding-top: 200px;
        .transition-05;
        @media (max-width: 992px) {
          margin-top: 20px;
          padding-top: 0;
        }

        .testimonial-block {
          display: flex;
          margin: 60px auto 100px;
          padding-bottom: 76px;
          .block-text.testimonial {
            font-size: 28px;
            line-height: 36px;
            @media (max-width: 576px) {
              font-size: 22px;
              line-height: 30px;
            }
          }
        }
      }
    }

    .sectors-page-sectors-block {
      .manrope;
      padding-bottom: 100px;
      .sectors-title {
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        width: fit-content;
        margin: 19px auto 80px;

        @media (max-width: 576px) {
          font-size: 18px;
          margin: 19px auto 0px;
          max-width: 215px;
        }
        &.bodered {
          border: 1px solid @gray6;
          padding: 15px 68px;
          border-radius: 12px;
          font-size: 16px;
        }
        &.please-note {
          @media (max-width: 576px) {
            padding: 13px 19px;
          }
        }
      }

      .sectors-scroll-container {
        width: 100%;
        overflow-x: scroll;
        // margin-bottom: 65px;
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-corner {
          display: none;
        }
        &.mobile {
          display: none;
        }
        @media (max-width: 724px) {
          &.desktop {
            display: none;
          }
          &.mobile {
            display: block;
            padding-bottom: 20px;
          }
        }
      }

      .sectors-wrapper {
        display: flex;
        gap: 30px;
        height: 480px;
        align-items: stretch;
        min-width: 1430px;
        @media (max-width: 1250px) {
          gap: 15px;
        }
        @media (max-width: 724px) {
          height: auto;
        }
        @media (max-width: 576px) {
          height: 600px;
        }


        .sector {
          position: relative;
          height: 100%;
          flex: 1;
          transition: flex 0.5s ease;
          position: relative;
          cursor: pointer;
          overflow: hidden;

          > img {
            object-fit: cover;
            height: 100%;
            border-radius: 22px;
          }

          &.mobile {
            height: 600px;
          }

          .sector-arrow-wrapper {
            position: absolute;
            bottom: 33px;
            left: 37px;
            display: flex;
            gap: 14px;

            .counter {
              color: @white;
              font-family: Manrope;
              font-weight: 300;
              font-size: 22px;
              line-height: 44px;
            }
          }

          .sector-title {
            color: @white;
            position: absolute;
            top: 33px;
            left: 37px;
            font-weight: 300;
            font-size: 42px;
            line-height: 52px;
          }

          &.active {
            flex: 2;
            min-width: 263px;
          }
        }

        .sub-info-wrapper {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          max-width: 0px;
          gap: 15px;
          border-radius: 10px;
          justify-content: center;
          overflow: hidden;
          opacity: 0;
          transition: opacity 0.5s ease, max-width 0.5s ease;
          .info-item {
            // flex: 1 1 calc(50% - 10px);
            width: 324px;
            height: 229px;
            background: @greenMain;
            border-radius: 22px;
            color: white;
            padding: 27px 35px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media (max-width: 1655px) {
              width: calc((100% - 15px) / 2 );
            }

            .title {
              font-weight: 300;
              font-size: 30px;
              line-height: 40px;
              width: 80%;
              @media (max-width: 1500px) {
                font-size: 24px;
              }
            }
          }
        }

        // .active + .sub-info-wrapper {
        //   max-width: 680px;
        // }
        .sub-info-wrapper.open {
          max-width: 680px;
          opacity: 1;
          @media (max-width: 1650px) {
            // max-width: 610px;
            max-width: calc(100% / 3);
          }
          @media (max-width: 1250px) {
            // max-width: 500px;
          }
        }

      }
    }

    .track-record-block {
      .manrope;
      margin-bottom: 196px;
      @media (max-width: 576px) {
        margin-bottom: 60px;
      }

      .filters-wrapper {
        display: flex;
        gap: 20px;
        margin: 0 auto;
        width: 100%;
        max-width: 872px;
        overflow-x: scroll;
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-corner {
          display: none;
        }
        .filter-item {
          min-width: fit-content;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: @greenMain;
          padding: 13px 20px;
          border: 1px solid @gray6;
          border-radius: 100px;
          cursor: pointer;
          .transition-03;
          &.active {
            border-color: @greenMain;
          }
          &:hover {
            border-color: @greenMain;
          }
        }

      }

      .track-records-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 31px;
        justify-content: center;
        width: 100%;
        max-width: 1366px;
        margin: 65px auto;
        // border: 1px solid red;

        .track-record-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background: @greenMain;
          width: 434px;
          height: 400px;
          border-radius: 22px;
          padding: 30px;
          opacity: 1;
          will-change: opacity;
          .transition-03;
          &.hidden {
            opacity: 0.9;
          }
          @media (max-width: 576px) {
            border-radius: 16px;
          }
          @media (max-width: 400px) {
            padding: 26px 10px 26px 26px;
          }
          // .top-wrapper {
          //   display: flex;
          //   flex-direction: column;
          //   border: 1px solid red;
          // }


          .images-wrapper {
            display: flex;
            gap: 24px;
            @media (max-width: 400px) {
              gap: 16px;
            }
          }
          .tags-wrapper{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            // @media (max-width: 372px) {
            //   flex-direction: column;
            // }

            .tag {
              width: fit-content;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              border: 1px solid @lightGreen;
              padding: 3px 14px;
              color: @main_orange;
              border-radius: 100px;
            }
          }
          .record-text {
            font-size: 18px;
            line-height: 28px;
            // margin-top: 14px;
            width: 80%;
          }
          .record-place {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            margin-top: 29px;
            color: @lightBlue2;
            width: fit-content;
          }
        }

      }

    }
  }
}

//for mobile version
.service-block-carousel-wrapper, .sectors-page-carousel-wrapper {
  height: auto;
  width: 100%;
  max-width: 500;
  margin-top: 20px;
  margin-bottom: 80px;
  &.sectors-page-carousel-wrapper {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: 40px;
    .ant-carousel .slick-slider.carousel-custom .slick-dots {
      bottom: -38px;
    }
  }
  .ant-carousel {
    .slick-slider.carousel-custom {
      .carousel-item-wrapper {

        &:has(.sector-item-carousel) {
          padding: 0 10px;
        }

        .sector-item-carousel {
          margin: 0 auto;
          position: relative;
          height: 600px;
          width: 100%;
          max-width: 450px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          row-gap: 14px;
          // border-radius: 22px;
          > img {
            border-radius: 16px;
          }
          .sector-carousel-content-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            padding: 28px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: linear-gradient(180deg, rgba(28, 76, 93, 0) 0%, rgba(28, 76, 93, 0.5) 100%);
            border-radius: 16px;
            .sector-title {
              font-size: 28px;
              line-height: 36px;
            }
            .sub-info-wrapper {
              display: flex;
              flex-direction: column;
              gap: 36px;
              .info-item {
                display: flex;
                justify-content: space-between;
                .title {
                  font-size: 22px;
                  line-height: 30px;
                }
              }
            }
          }
        }

        .service-block-item-carousel {
          margin: 0 auto;
          position: relative;
          height: 320px;
          width: 318px;
          padding: 30px 32px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          row-gap: 14px;
          border-radius: 16px;
          background: @lightBlue;
          will-change: height, background, color;
          .transition;

          .arrow-wrapper {
            width: 44px;
            height: 44px;
            border-radius: 100px;
            border: 1px solid @lightBlue2;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 30px;
            left: 32px;
            will-change: width, color, justify-content, padding;
            .transition;
            &:hover {
              border-color: @white;
            }
          }

          .text {
            text-align: start;
            // width: 50%;
            font-size: 30px;
            font-weight: 300;
            line-height: 40px;
            transition: color 0.3s ease;
          }

          .sub-text, .learn-more {
            display: none;
            font-family: Manrope, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: @white;
            opacity: 0;
            will-change: display, opacity, content;
            .transition;
          }
          .learn-more {
            white-space: nowrap;
            font-size: 14px;
          }

          &.like-hover {
            height: 392px;
            background: @greenMain;
            svg.icon {
              path {
                fill: @white;
              }
              // transform: rotate(45deg);
            }
            .text {
              color: @white;
            }
            .arrow-wrapper {
              border: 1px solid @lightGreen;
              justify-content: space-between;
              width: 187px;
              padding: 0 16px;
              cursor: pointer;
              &:hover {
                border-color: @white;
              }
              .arrow {
                path {
                  fill: @white;
                }
                fill: @white;
                transform: rotate(45deg);
              }
            }
            .sub-text, .learn-more {
              display: block;
              opacity: 1;
            }
          }
        }
      }
      .slick-dots {
        bottom: -24px;
        margin: 0;
      }
      .slick-dots li {
        background-color: @gray5;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        opacity: 0.6;
      }
      .slick-dots li.slick-active {
        background-color: #9DA4B0;
        opacity: 1;
      }
      .slick-dots li button {
        width: 8px;
        height: 8px;
        opacity: 0;
      }
    }
  }
}

@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;