@import '../../variables.less';

.advisordealmain-container {
  width: 100%;
  height: calc(100Vh - 133px);
  display: flex;
  flex-direction: column;
  background-color: @gray_bg3;
  padding: 0 60px;
  overflow: auto;
  font: normal normal 700 24px Poppins;
  @media (max-width: 1440px) {
    font-size: 20px;
  }
  @media (max-width: 1199px) {
    height: calc(100Vh - 100px);
    font-size: 16px;
  }
  &.admin {
    padding: 0;
    height: calc(100Vh - 238px);
    .section-targetcompany {
      margin: 0;
      padding: 24px;
      height: auto;
      margin-right: 16px;
      .section-text {
        margin-right: 0;
        flex-wrap: wrap;
        color: @main;
      }
    }
    .section-status {
      margin: 0 0 24px;
      padding: 24px;
      .section-row {
        height: auto;
        > span:first-child {
          margin-right: 32px;
          color: @main;
        }
        .text {
          margin: 0;
          margin-right: auto;
          color: @main;
        }
      }
      .text {
        margin: 0 auto 0 32px;
        width: auto;
      }
      .wrapper {
        width: auto;
      }
      .blue {
        margin: 0;
      }
      .section-subtitle {
        color: @main;
      }
    }
  }

  .blue {
    font: normal normal bold 18px/24px Poppins;
    display: flex;
    align-items: center;
    @media (max-width: 1440px) {
      font-size: 16px;
    }
    @media (max-width: 1199px) {
      font-size: 14px;
    }
  }

  .section-top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .section-targetcompany {
    width: 572px;
    height: 68px;
    margin-top: 24px;
    background-color: @white;
    padding: 8px 28px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0px 3px 60px #F0F0F7;
    border-radius: 30px;
    &.dealoverwiew {
      width: fit-content;
      display: inline-flex;
      padding: 8px 18px;
      margin-right: 24px;
    }
    .section-text {
      display: flex;
      justify-content: center;
      margin-right: 120px;
      width: fit-content;
      &.dealoverwiew {
        margin-right: 0;
        width: fit-content;
        display: inline-flex;
        align-items: center;
      }
      > span {
        // width: 240px;
        &.blue {
          display: flex;
          justify-content: center;
          width: fit-content;
          height: 40px;
          border-radius: 30px;
          color: @blue_light;
          background-color: @gray_bg;
          margin-left: 10px;
          &.dealoverwiew {
            display: inline-flex;
            padding: 0 16px;
            a {
              color: @main;
              font: normal normal bold 19px/24px Poppins;
              text-decoration: underline;
              &:hover {
                color: @blue;
              }
            }
          }
        }
      }
    }
  }
  .section-status{
    max-width: 1392px;
    margin-top: 16px;
    background-color: @white;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 60px #F0F0F7;
    border-radius: 30px;

    .section-divider {
      height: 3px;
      width: 90%;
      background-color: @gray_divider;
      margin: 12px auto;
    }

    .section-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      height: 49%;
      width: 100%;
      .text {
        margin: 0 auto 0 32px;
        width: auto;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      margin: 0;
      button.invite {
        width: 232px;
        font: normal normal bold 18px Poppins;
        color: @blue_light;
        @media (max-width: 1440px) {
          font-size: 16px;
        }
        &:hover {
          color: @white;
        }
      }
    }

    .section-row-title {
      margin: 0 5% 0 0;
    }

    .section-subtitle {
      font: normal normal 600 18px Poppins;
      margin-right: 32px;
      white-space: nowrap;
      @media (max-width: 1440px) {
        font-size: 16px;
      }
      @media (max-width: 1199px) {
        font-size: 14px;
      }
    }

    .text {
      width: 570px;
      margin: 0 40px;
      font: normal normal 500 18px Poppins;
      @media (max-width: 1440px) {
        font-size: 16px;
      }
      @media (max-width: 1199px) {
        font-size: 14px;
      }
    }

    .blue {
      display: flex;
      justify-content: center;
      padding: 0 35px;
      margin: 12px 0;
      height: 40px;
      border-radius: 30px;
      color: @blue_light;
      background-color: @gray_bg;
      white-space: nowrap;
    }
  }
  .section-action {
    display: flex;

    .section-action-title {
      width: fit-content;
      border-bottom: 4px solid @orange;
      position: relative;
      img {
        position: absolute;
        top: -5px;
        left: 102%;
      }
    }

    .section-col1 {
      width: 572px;
      margin-right: 32px;

      .section-actions {
        max-height: 230px;
        width: 100%;
        margin-top: 16px;
        background-color: @white;
        padding: 22px 28px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 3px 60px #F0F0F7;
        border-radius: 30px;
        overflow: auto;

        .section-actions-content {
          display: flex;
          flex-direction: column;
          overflow: auto;
          width: 100%;
          margin-top: 12px;

          &.history {
            display: flex;
            justify-content: flex-start;
            max-height: 230px;
            overflow: auto;
            .text span:first-child {
              margin-right: 12px;
              white-space: nowrap;
            }
            .text span:last-child {
              margin-left: 24px;
              margin-right: 12px;
              cursor: pointer;
              text-align: right;
              white-space: pre-line;
            }
          }
          .text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;
            font: normal normal 500 16px Poppins;
            @media (max-width: 1440px) {
              font-size: 14px;
            }
            @media (max-width: 1199px) {
              font-size: 12px;
            }
            button {
              margin: 0 12px;
            }
          }
        }
      }
    }

    .section-col2 {
      width: 780px;
      .section-communication {
        width: 100%;
        margin-top: 16px;
        background-color: @white;
        padding: 24px 24px 8px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 3px 60px #F0F0F7;
        border-radius: 30px;
        .ant-collapse-arrow{
          // margin-top: -42px;
          margin-top: -20px;
          margin-right: 10px;
        }
        .ant-collapse-header {
          padding: 6px;
        }
        .section-communication-content {
          display: flex;
          flex-wrap: wrap;

          .square {
            position: relative;
            margin: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 10px;
            width: 160px;
            height: 136px;
            border: 1px solid @gray_divider;
            border-radius: 8px;
            @media (max-width: 1440px) {
              width: 130px;
              height: 120px;
              margin: 4px;
            }
            &.open {
              border: none;
            }
            &:hover {
              cursor: pointer;
            }
            .square-title {
              font: normal normal 600 18px Poppins;
              @media (max-width: 1440px) {
                font-size: 16px;
              }
              @media (max-width: 1199px) {
                font-size: 14px;
              }
            }
            .image {
              width: 35px;
              margin-bottom: 6px;
            }
            .text-light {
              width: 80%;
              font: normal normal 300 12px Poppins;
            }
            .note-img {
              position: absolute;
              left: 145px;
              top: -12px;
              @media (max-width: 1440px) {
                left: 109px;
              }
            }
          }
        }
        .documents-collapse {
          display: flex;
          width: 90%;
          height: 124px;
          border: 1px solid @gray_divider;
          border-radius: e('35px / 20px');
          padding: 12px 0;
          &.clone {
            height: auto;
            border: none;
            width: 100%;
            overflow: auto;
            padding: 0 14px;
            flex-wrap: wrap;
            &::-webkit-scrollbar {
              width: 8px;
              height: 80%;
              background: #dfdcdc;
              border-radius: 1ex;
            }
          }
        }
        .document {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 90px;
          border: 1px solid @gray_divider;
          border-radius: e('7px / 12px');
          margin: 4px 12px;
          &:hover {
              cursor: pointer;
            }
        }
        .document-icon {
          width: 30px;
        }
        .document-title {
          font: normal normal 500 10px Poppins;
					color: @main;
          width: 100%;
          text-align: center;
          margin-top: -2px;
          width: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .document-title-date {
          font: normal normal 400 8px Poppins;
          margin-top: -1px;
          text-align: center;
        }
      }

      .section-advisorywork {
				width: 100%;
				margin-top: 12px;
				background-color: @white;
				padding: 24px 24px 8px;
				display: flex;
				flex-direction: column;
				box-shadow: 0px 3px 60px #f0f0f7;
				border-radius: 30px;

				.ant-collapse-arrow {
					// margin-top: -42px;
					margin-top: -20px;
					margin-right: 10px;
				}

        .ant-collapse-header {
          padding: 6px;
        }

				.section-advisorywork-content {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 100%;
					min-width: 340px;

					.mainblock-wrapper {
						width: 100%;
						border: 1px solid @gray_divider;
						min-height: auto;
						border-radius: 30px;
						padding: 12px 24px;

						.title {
							font: normal normal 700 16px Poppins;
							border-bottom: 3px solid @orange;
							color: @main;
							margin-right: 5px;
              @media (max-width: 1440px) {
                font-size: 14px;
              }
              @media (max-width: 1199px) {
                font-size: 12px;
              }
						}
						.value {
							font: normal normal 500 16px Poppins;
              color: @main;
							margin-left: 10px;
              @media (max-width: 1440px) {
                font-size: 14px;
              }
              @media (max-width: 1199px) {
                font-size: 12px;
              }
						}

						.ant-btn.btn-primary.green,
						.ant-btn.btn-primary.green:focus {
							background: @white;
							color: @black;
							border: 1px solid #2edb3b;
						}
						.ant-btn.btn-primary.green.btn-primary.adwork-btn {
							margin-left: 20px;
							width: 126px;
							height: 30px !important;
							font: normal normal 600 15px Poppins;
							color: @main;
						}

						.ant-btn.btn-primary.green:hover {
							background: @blue_light;
							color: @white;
						}
						.ant-btn.btn-primary.green:active {
							background: #2edb3b;
							color: @white;
						}

						.ant-divider.ant-divider-horizontal {
							border-top: 2px solid rgba(0, 0, 0, 0.06);
							margin: 12px 0;
						}

						.submition-block {
							display: flex;

							.square {
								margin: 0px 10px 0px 0px !important;
								display: flex;
                position: relative;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								text-align: center;
								padding: 4px;
								width: 110px;
								height: 83px;
								border: 1px solid @gray_divider;
								border-radius: 8px;
                &.hover {
                  &:hover {
                    cursor: pointer;
                  }
                }
                &.orange{
                  border: 1px solid @orange;
                  .bell{
                    position: absolute;
                    top: -10px;
                    right: -10px;
                  }
                  &:hover {
                    cursor: pointer;
                  }
                }
								&.active {
                  border: 1px solid #3680F6;
                  &:hover {
                    cursor: pointer;
                  }
                }
								.text {
									font: normal normal 500 10px Poppins;
									color: @main;
                  &.orange{
                    color: @orange;
                  }
                  &.active {
                    color: #3680F6;
                  }
								}
								.image {
									width: 27px;
									margin-bottom: 12px;
                  &.active {
                    color: #3680F6;
                  }
								}
							}
              .submisison-content {
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .submisison-text {
                  font: normal normal 700 12px Poppins;
                  color: @main;
                }
                .submisison-data {
                  font: normal normal 400 12px Poppins;
                  margin-left: 10px;
                  color: @main;
                  &.orange{
                    color: @orange;
                  }
                }
              }
						}
					}

					.secondaryblock {
						width: 100%;
						display: flex;
						justify-content: space-between;
						margin-top: 20px;

						.left {
							display: flex;
							width: 70%;
							border: 1px solid @gray_divider;
							min-height: 80px;
							border-radius: e("25px/10px");
							padding: 8px;
							.price {
                display: flex;
                justify-content: center;
								font: normal normal 700 20px Poppins;
								color: @main;
								&.blue {
									color: @blue_light;
								}
							}
							.price-title {
								font: normal normal 500 12px Poppins;
								text-align: center;
								color: @main;
							}
						}

						.right {
							display: flex;
							justify-content: center;
							flex-direction: column;
							width: 24%;
							.right-data {
								font: normal normal 500 14px Poppins;
								display: flex;
								justify-content: center;
								align-items: center;
								width: 100%;
								border: 1px solid @gray_divider;
								height: 40px;
								margin-top: 10px;
                border-radius: e("10px/5px");
								color: @main;
							}
							.subscription {
                margin-top: 6px;
								text-align: center;
								font: normal normal 400 10px Poppins;
								color: @main;
							}
						}
					}
				}
			}
		}
	}
}

.ant-btn.btn-primary.red-margin,
.ant-btn.btn-primary.red-margin:focus {
	background: @white;
	color: @black;
	border: 1px solid @red;
}
.ant-btn.btn-primary.red-margin.btn-primary.actions-btn {
	margin-left: 20px;
	width: 126px;
	height: 30px !important;
	font: normal normal 600 15px Poppins;
}

.ant-btn.btn-primary.red-margin:hover {
	background: @red;
	color: @white;
}

.ant-btn.btn-primary.red-margin:active {
	background: #f80707;
	color: @white;
}

.site-collapse-custom-collapse {
	width: 100%;
	background-color: @white !important;
}

&::-webkit-scrollbar-thumb {
	background: #232d53;
	-webkit-border-radius: 1ex;
	-webkit-box-shadow: 0px 1px 2px rgba(170, 168, 168, 0.75);
}
&::-webkit-scrollbar {
	width: 12px;
	background: #dfdcdc;
	border-radius: 1ex;
  @media (max-width: 1199px) {
    width: 8px;
    height: 8px;
  }
}
&::-webkit-scrollbar-corner {
	background: #dfdcdc;
}

@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;