@import 'variables.less';

/* Layout */
body, html {
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	line-height: 16px;
	--antd-wave-shadow-color: transparent;
	color: @main;
}

a {
	color: @main;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	cursor: pointer;
	@media (max-width: 1440px) {
    font: normal normal 500 0.94vw/1.25vw Poppins;
  }
}

.link-tag {
	font: normal normal 600 19px/24px Poppins;
	cursor: pointer;
}

a:hover,
.link-tag:hover {
	color: @blue;
}

.link-tag.underline {
	text-decoration: underline;
}

h1, h2, h3 {
	font-family: 'Poppins', sans-serif;
	color: @main;
	margin: 0;
}

h1 {
	font-size: 64px;
	line-height: 130%;
	font-style: normal;
	font-weight: bold;
	color: #1fcb8a;
}

h2 {
	font-size: 48px;
	line-height: 130%;
	font-style: normal;
	font-weight: bold;
}

h3 {
	font-size: 22px;
	line-height: 130%;
	font-style: normal;
	font-weight: bold;
}

.main-layout {
	width: 100%;
	height: 100vh;
}

.page-full-wrapper {
	width: 100%;
	height: 100vh;
}

.ant-layout {
	background: @white;
}

.ant-layout-content {
	background-color: #eaedf3;
}

.ant-layout-sider-children {
	display: flex;
	flex-direction: column;
}

.main-layout .ant-layout-header {
	background-color: @main;
	width: 100%;
	height: 133px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	padding: 0 80px;
	@media (max-width: 1199px) {
		height: 100px;
	}
	.header-title {
		font: normal normal 600 28px/40px Poppins;
		color: @white;
		text-transform: capitalize;
		@media (max-width: 1199px) {
			font-size: 24px;
		}
	}
	.header-action img {
		margin-left: 54px;
		cursor: pointer;
	}
}

.sidebar {
	height: 100vh;
	background-color: @white;
	.logo-section {
		width: 18.6vw;
		height: 133px;
		border-bottom: 1px solid @gray_border;
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width: 1199px) {
			height: 100px;
		}
		img {
			width: 11.5vw;
		}
	}
	.nav-content {
		width: 18.6vw;
		height: calc(100vh - 133px);
		padding: 2.9vw 0 1.67vw;
		display: flex;
		flex-direction: column;
		overflow: auto;
		@media (max-width: 1199px) {
			height: calc(100vh - 100px);
		}
		.nav-section {
			margin-bottom: 2vw;
			&:last-of-type {
				margin-top: auto;
				margin-bottom: 0;
			}
			.nav-title {
				font: normal normal bold 18px/24px Poppins;
				margin-bottom: 6px;
				margin-left: 3.54vw;
				@media (max-width: 1440px) {
					font: normal normal bold 14px/18px Poppins;
				}
				@media (max-width: 1199px) {
					font: normal normal bold 12px/16px Poppins;
				}
			}
			.nav-divider {
				height: 3px;
				background-color: rgba(35, 45, 83, 0.15);
				border-radius: 8px;
				margin-bottom: 1.67vw;
				margin-left: 3.54vw;
			}
			.nav-item {
				height: 42px;
				padding-left: 3.54vw;
				display: flex;
				align-items: center;
				cursor: pointer;
				border-right: 3px solid transparent;
				&.btn {
					cursor: default;
					> span {
						font-weight: normal;
						cursor: pointer;
						&:hover {
							font-weight: bold !important;
							cursor: pointer !important;
						}
					}
					&:hover {
						background-color: @white;
						border-right-color: @white;
						> span {
							font-weight: normal;
							cursor: default;
						}
					}
				}
				> div {
					width: 44px;
				}
				> span {
					font: normal normal normal 18px/24px Poppins;
					@media (max-width: 1440px) {
						font: normal normal normal 14px/18px Poppins;
					}
					@media (max-width: 1199px) {
						font: normal normal normal 12px/16px Poppins;
					}
				}
				.return-dashboard {
					font-size: 16px;
					font-weight: 500;
					@media (max-width: 1440px) {
						font-size: 14px;
					}
					@media (max-width: 1199px) {
						font-size: 12px;
					}
				}
				> img {
					margin: 0 16px 0 auto;
					@media (max-width: 1440px) {
						width: 18px;
					}
				}
				&:hover, &.active {
					background-color: @gray_bg3;
					border-right-color: @blue_light;
					> span {
						font-weight: 600;
						color: @main;
					}
				}
			}
		}
	}
}

.experience-list {
	width: 100%;
	.list-header {
		display: flex;
		justify-content: space-around;
		align-items: center;
		> div {
			font: normal normal 600 15px/23px Poppins;
			min-width: 100px;
			text-align: center;
		}
	}
	.list-item {
		position: relative;
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: 12px 0 16px;
		padding: 12px;
		background-color: @white;
		border-radius: 40px;
		> div {
			font: normal normal normal 13px/18px Poppins;
			min-width: 100px;
			text-align: center;
			&.no-wrap {
				white-space: nowrap;
			}
		}
		.remove-item {
			width: 12px;
			position: absolute;
			right: 0;
			bottom: 100%;
			cursor: pointer;
		}
	}
}

.add-another-one {
	display: inline-flex;
	align-items: center;
	margin-left: 32px;
	margin-top: 24px;
	cursor: pointer;
	> img {
		margin-right: 6px;
	}
	> span {
		font: normal normal 600 13px/20px Poppins;
		text-decoration: underline;
	}
}

.day-rate {
	width: 220px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 12px;
	.experience-title {
		align-self: flex-start;
		width: 150px;
		margin-left: 10px;
		> span {
			margin: 0 auto;
		}
	}
	.ant-input-number {
		width: 152px;
		input {
			text-align: center;
		}
	}
	.rate-currency {
		font: normal normal 500 16px/24px Poppins;
	}
}

.main-container {
  width: 100%;
  height: calc(100Vh - 133px);
  display: flex;
  flex-direction: column;
  background-color: @gray_bg3;
  padding: 0 2.54vw;
  overflow: auto;
	@media (max-width: 1199px) {
		height: calc(100vh - 100px);
		padding: 20px 2.25vw;
	}
  &.match-company {
    .ant-spin-container {
      overflow: hidden;
    }
  }
  &.admin-container {
    // padding: 32px 88px;
		padding: 16px 2.25vw;
		@media (max-width: 1199px) {
			height: calc(100vh - 100px);
			// padding: 16px 2.25vw;
		}
  }
	&.expertise {
		display: block;
	}
  .action-panel {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    position: relative;
		@media (max-width: 1199px) {
			padding-bottom: 16px;
		}
  }
  .table-container {
    height: 100%;
    overflow: auto;
  }
  .filter-panel {
    position: absolute;
    top: 100%;
    z-index: 2;
    width: 100%;
    background-color: @white;
    border-radius: 5px;
    padding: 24px 40px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.4);
		@media (max-width: 1440px) {
      font: normal normal normal 1.12vw/1.25vw Poppins;
    }
		@media (max-width: 1199px) {
			padding: 16px;
		}
    .filter-title {
      margin-right: 16px;
      min-width: 90px;
      width: 90px;
			@media (max-width: 1199px) {
				min-width: 40px;
				margin-right: 10px;
			}
    }
    .site-input-split {
      width: '10%';
      border-left: 0;
      border-right: 0;
      pointer-events: none;
      padding: 0;
      text-align: center;
    }
    .filter-actions {
      // position: absolute;
      // right: 40px;
      // bottom: 24px;
      .clear-all {
        color: @orange;
        text-decoration: underline;
        font: normal normal normal 15px/24px Poppins;
        cursor: pointer;
        margin-right: 40px;
      }
    }
    .filter-clear {
      margin-left: 16px;
      cursor: pointer;
      &.hidden {
        cursor: default;
        visibility: hidden;
      }
			@media (max-width: 1199px) {
				margin-left: 6px;
			}
    }
    .ant-input-number {
      margin-right: 0;
      input {
        text-align: center;
      }
    }
    .ant-select-single {
      min-width: 100%;
    }
  }
  .red-text {
    color: #F62301;
  }
  .section-content {
    background-color: @white;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1125px;
    margin-bottom: 32px;
    box-shadow: 0px 3px 60px #F0F0F7;
    border-radius: 30px;
		@media (max-width: 1199px) {
      flex-wrap: nowrap;
			padding: 12px;
    }
		&.stat {
			justify-content: space-around;
			@media (max-width: 1199px) {
				flex-wrap: wrap;
			}
		}
    &.bottom {
			margin-top: 3%;
      flex-direction: column;
      .section-title {
        font: normal normal 700 24px/40px Poppins;
      }
      .section-text {
        text-align: center;
        margin-right: 0;
      }
    }
    &.info {
      max-width: initial;
      // min-height: 136px;
      // font: italic normal normal 22px/50px Poppins;
      color: @main;
			font: italic normal normal 1.54vw/2.45vw Poppins;
			padding: 5% 3%;
    }
    &.deal-data {
      max-width: initial;
      flex-direction: column;
      align-items: flex-start;
      color: @main;
      padding-bottom: 16px;
      .deal-title {
        font: normal normal 600 22px/48px Poppins;
      }
      .deal-info {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .deal-info-item {
          display: flex;
          align-items: center;
          margin: 16px 40px 16px 0;
          > span {
            font: normal normal 500 19px/24px Poppins;
            margin-right: 32px;
          }
          > div {
            background-color: #EBEFFC;
            border-radius: 20px;
            color: @blue_light;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            font: normal normal bold 19px/24px Poppins;
            padding: 8px 32px;
          }
        }
        > button {
          margin-left: auto;
        }
      }
    }
    &.match-filters {
      max-width: initial;
      margin-bottom: 24px;
      padding: 16px 280px 40px 16px;
    }
    &.t-cs {
      max-width: initial;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid #707070;
      margin-bottom: 24px;
      .terms-conditions {
        border: 1px solid #D3CFCF;
        border-radius: 15px;
        padding: 24px;
        height: calc(100Vh - 316px);
        overflow: auto;
        width: 100%;
        display: flex;
        align-items: center;
        .Terms {
          padding: 0;
          margin: 0;
          max-width: initial;
          h3:last-child {
            margin-bottom: 0;
          }
        }
        .not-agreement {
          font: normal normal bold 19px/24px Poppins;
          width: 100%;
          text-align: center;
        }
      }
    }
    .section-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 6vw;
      > span {
        font: normal normal 500 1.15vw/2.6vw Poppins;
        &.blue {
          color: @blue;
        }
      }
    }
    .deal-btn.ant-btn {
      width: 15.6vw;
      height: 3.65vw !important;
      font: normal normal bold 1.46vw/2vw Poppins;
    }
    .coming-soon {
      width: 300px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid @orange;
      border-radius: 40px;
      font: normal normal bold 28px/42px Poppins;
      color: @orange;
      background-color: @white;
      // transition: all 0.3s linear;
      > span:last-of-type {
        display: none;
      }
      .share-now-link {
        font: normal normal bold 28px/42px Poppins;
        color: @orange;
      }
      // &:hover {
      //   width: 217px;
      //   height: 52px;
      //   border: none;
      //   font: normal normal bold 21px/32px Poppins;
      //   color: @white;
      //   background-color: #112B89;
      //   > span:first-of-type {
      //     display: none;
      //   }
      //   > span:last-of-type {
      //     display: inline-block;
      //   }
      // }
    }

    .section-count {
      display: flex;
      align-items: center;
			justify-content: space-around;
      padding: 6px 32px;
      min-width: 325px;
      color: @main;
			@media (max-width: 1199px) {
				min-width: 254px;
				padding: 4px 16px;
			}
      > span {
        font: normal normal 500 1.1vw/1.25vw Poppins;
        margin-right: 20px;
				width: 180px;
      }
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 74px;
        height: 40px;
        background-color: #EBEFFC;
        border-radius: 21px;
				font: normal normal 500 1.1vw/1.25vw Poppins;
        color: @blue_light;
				@media (max-width: 1199px) {
					min-width: 54px;
					height: 30px;
				}
      }
    }
  }
  .section-search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .section-search-bar-section {
      display: flex;
      align-items: center;
      background-color: @white;
      border: 1px solid #D7D7DA;
      padding: 8px 16px;
      border-radius: e("20px / 40px");
      margin-top: 24px;
      .ant-dropdown-link {
        width: 150px;
        height: 35px;
        border: 1px solid #707070;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 8px;
        margin-left: 16px;
      }
    }
    .ant-btn {
      font: normal normal normal 19px/29px Poppins;
      border-radius: e("20px / 40px");
    }
  }
  .matching-filters {
    display: flex;
    flex-direction: column;
    max-width: 1230px;
    margin-bottom: 32px;
    > .matching-filters-header {
      display: flex;
      justify-content: space-around;
      align-items: center;
      > span {
        font: normal normal 600 22px/33px Poppins;
        color: @main;
        flex: 1;
        text-align: center;
      }
      > div {
        flex: 1;
        text-align: right;
        img {
          cursor: pointer;
        }
      }
    }
    > .matching-filters-content {
      background-color: @white;
      padding: 24px 24px 8px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 3px 20px #F0F0F7;
      border-radius: 30px;
      > .matching-filters-main {
        display: flex;
        justify-content: space-around;
        > div {
          flex: 1;
          &.matching-filters-data {
            margin-right: 16px;
            text-align: center;
            > div {
              width: fit-content;
              min-height: 41px;
              padding: 8px 32px;
              background-color: #EBEFFC;
              border-radius: 21px;
              font: normal normal 600 16px/25px Poppins;
              color: @blue_light;
              margin: 0 auto 16px;
              text-align: initial;
            }
          }
          &.matching-filters-data-double {
            flex: 3;
            text-align: center;
            display: flex;
            flex-direction: column;
            .matching-filters-subdata {
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              > .subdata-item {
                flex: 1;
                width: fit-content;
                min-height: 41px;
                padding: 8px 32px;
                background-color: #EBEFFC;
                border-radius: 21px;
                font: normal normal 600 16px/25px Poppins;
                color: @blue_light;
                margin: 0 16px 16px 0;
                text-align: initial;
                &.sub-sector {
                  flex: 2;
                  margin-right: 0;
                }
              }
              > .sector-add-spec {
                display: flex;
                width: 100%;
                margin-bottom: 16px;
                color: @main;
                > div {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  > span {
                    font: normal normal 500 14px/21px Poppins;
                    margin-right: 16px;
										flex: 1;
										padding: 0 32px;
                  }
                  > div {
										flex: 2;
                    background-color: @white;
                    box-shadow: 0px 3px 60px #F0F0F7;
                    border-radius: 30px;
                    height: 36px;
                    display: flex;
                    align-items: baseline;
                    padding: 0 32px;
                    font: normal normal normal 14px/36px Poppins;
                    width: calc(100% - 220px);
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .details-section {
    display: flex;
    .details-block {
      width: 100%;
    }
    .details-divider {
      width: 3px;
      height: calc(100% + 24px);
      background-color: rgba(35, 45, 83, 0.15);
      border-radius: 8px;
      margin: -24px 64px 0;
    }
  }
  .details-info {
    background-color: @white;
    padding: 32px 32px 20px;
    border: 1px solid #D3CFCF;
    border-radius: 15px;
    &.advisor-settings {
      width: 500px;
      margin: 0 auto;
    }
    .ant-input-affix-wrapper {
      height: 42px;
      border-top: 1px solid #E5E9EE;
      border-bottom: 1px solid #E5E9EE;
      &:hover,
      &:focus {
        border-top: 1px solid#aee4f5;
        border-bottom: 1px solid#aee4f5;
      }
    }
    .ant-input {
      height: 40px;
      border: none;
    }
    .ant-form-item-has-error .ant-input-affix-wrapper {
      border-color: #C63009;
    }
  }
  .add-info-questions {
    margin: 40px;
    padding: 32px;
    background-color: @white;
    border: 1px solid #D3CFCF;
    border-radius: 15px;
    font: normal normal 500 15px/24px Poppins;
    color: @main;
    @media (max-width: 1440px) {
      margin: 40px 0;
    }
    .add-info-value {
      width: 70px;
      font-size: 17px;
      text-align: center;
    }
  }
  .section-divider {
    max-width: 1342px;
    height: 3px;
    background-color: rgba(35, 45, 83, 0.15);
  }
  .section-filter {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    > * {
      margin-right: 50px;
    }
    > span {
      font-size: 18px;
      font-weight: 500;
    }
    > a {
      font-size: 15px;
      font-weight: 500;
    }
  }
  .experience-block {
    display: flex;
    padding: 16px 0;
    .experience-left {
      flex: 2;
      padding-right: 22px;
      border-right: 3px solid rgba(35, 45, 83, 0.15);
      margin-right: 22px;
    }
    .experience-right {
      flex: 1;
      .experience-list {
        width: 100%;
        .list-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 12px;
          > div {
            font: normal normal 600 15px/23px Poppins;
            min-width: 150px;
            text-align: center;
						@media (max-width: 1199px) {
							font-size: 13px;
						}
          }
        }
        .list-item {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 12px 0 16px;
          padding: 12px;
          background-color: @white;
          border-radius: 40px;
          > div {
            font: normal normal 500 12px/18px Poppins;
            min-width: 150px;
            padding: 0 12px;
            text-align: center;
            white-space: nowrap;
            &.no-wrap {
              white-space: nowrap;
            }
          }
          .remove-item {
            width: 12px;
            position: absolute;
            right: 0;
            bottom: 100%;
            cursor: pointer;
          }
        }
      }
    }
    .experience-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font: normal normal 600 22px/32px Poppins;
			@media (max-width: 1440px) {
				font-size: 18px;
			}
			@media (max-width: 1199px) {
				font-size: 14px;
			}
      > img {
        cursor: pointer;
      }
    }
    .not-active {
      margin-bottom: 16px;
      border: 1px solid #D3CFCF;
      border-radius: 15px;
      font: normal normal 600 10px/15px Poppins;
      color: @main;
      padding: 14px;
      background-color: @white;
      margin-bottom: 32px;
    }
    textarea.ant-input {
      margin-bottom: 50px;
      border: 1px solid #D3CFCF;
      border-radius: 15px;
      font: italic normal 300 12px/18px Poppins;
      color: @main;
      padding: 14px;
      height: 140px;
      &::placeholder {
        font: normal normal 600 10px/15px Poppins;
        color: @main;
      }
    }
  }
  .investor-details {
    width: 738px;
    margin: auto;
    display: flex;
    flex-direction: column;
    .section-title {
      align-self: center;
      color: @main;
    }
    .section-switcher {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
			margin-bottom: 40px;
      color: @main;
      > span:first-child {
        font: normal normal 600 22px/32px Poppins;
      }
      > span:last-child {
        font: normal normal bold 19px/32px Poppins;
        color: @blue_light;
        background: #EBEFFC 0% 0% no-repeat padding-box;
        border-radius: 21px;
        padding: 8px 32px;
      }
    }
    .details-info {
      position: relative;
      > img {
        position: absolute;
        right: 24px;
        top: 8px;
        cursor: pointer;
      }
    }
  }
  .top-btn-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0;
    button {
      margin: 0 20px;
    }
  }
  .locked-list {
    width: 60Vw;
    height: calc(100Vh - 378px);
    margin-bottom: 24px;
    background-color: @white;
    margin: 0 auto;
    @media (max-width: 1300px) {
      width: 100%;
    }
    .locked-list-header {
      height: 60px;
      border-bottom: 1px solid rgba(35, 45, 83, .1);
      display: flex;
      align-items: center;
      > div {
        flex: 1;
        display: flex;
        justify-content: center;
        font: normal normal normal 16px/24px Poppins;
        color: rgba(35, 45, 83, 0.43);
      }
    }
    .locked-list-content {
      display: flex;
      height: calc(100% - 60px);
      overflow: auto;
      > div {
        flex: 1;
        &.unlocked {
          background-color: #EEEFF6;
        }
      }
      .locked-list-item {
        height: 56px;
        font: normal normal normal 16px/24px Poppins;
        color: @main;
        display: flex;
        align-items: center;
        padding: 0 24px;
        > div {
          flex: 1;
        }
        .locked-time {
          display: flex;
          justify-content: flex-end;
          > span:last-child {
            width: 100px;
            text-align: right;
            opacity: 0.6;
            margin-left: 8px;
          }
        }
      }
    }
  }
  .templates-block {
    width: 560px;
    min-height: 680px;
    background: @white;
    border-radius: 30px;
    margin: auto;
    display: flex;
    flex-direction: column;
    .select-section {
      background-color: #EBEBF0;
      padding: 40px 64px 24px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }
    .content-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 32px 64px;
      .template-variable-title,
      .variable-text,
      .variable-value {
        font: normal normal 300 14px/24px Poppins;
        color: rgba(35, 45, 83, 0.7);
      }
      .variable-value {
        font: normal normal 500 14px/24px Poppins;
        cursor: pointer;
      }
      #template_form_description {
        margin: 24px 0 32px;
      }
      .ant-btn {
        width: 200px;
        margin: auto auto 0;
      }
      .content-section-message {
        margin: auto;
        font: normal normal 500 32px/160% Poppins;
        color:  rgba(35, 45, 83, 0.6);
        width: 70%;
        text-align: center;
      }
    }
  }
  .focus-manager {
    background-color: @white;
    border-radius: 30px;
    flex: 1;
    padding: 32px 64px;
    .plus-tag {
      height: 32px;
      width: 32px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      margin-bottom: 16px;
      background: #F7F7FB;
      border-radius: 3px;
      font: normal normal 600 16px/24px Poppins;
      color: @orange;
      cursor: pointer;
    }
    .tag-input {
      display: inline-flex;
      height: 32px;
      width: 150px;
      .ant-input {
        height: 30px;
      }
    }
  }
  .ant-tabs {
    height: 100%;
  }
	@media (max-height: 899px) {
		.ant-tabs {
			min-height: 100%;
		}
	}
}

.firm-profile-block {
	padding: 0 96px;
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	height: calc(100vh - 262px);
	overflow: auto;
	&.match {
		padding: 0;
		height: auto;
		max-width: 1320px;
		width: 100%;
		overflow: initial;
		margin-left: auto;
		margin-right: auto;
		&.deal-share-info {
			width: 100%;
			max-width: 1392px;
			margin-bottom: 0;
		}
		.firm-profile-section-top {
			padding: 24px 64px;
			.firm-profile-input {
				flex: 1;
				> input {
					width: 350px;
				}
			}
			&.dashboard {
				.firm-profile-input {
					flex-basis: 100%;
					margin-right: 0;
					> span {
						width: 240px;
						color: @main;
					}
					> input {
						width: 750px;
					}
					> textarea {
						min-height: 42px;
						width: 750px;
						max-width: 30vw;
						line-height: 30px;
					}
				}
			}
			&.inv-det {
				padding: 12px 64px;
			}
		}
		.firm-profile-section-middle {
			position: relative;
			background-color: @white;
			box-shadow: 0px 3px 60px #f0f0f7;
			border-radius: 30px;
			padding: 24px 64px;
			display: flex;
			flex-direction: column;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 24px;
			> * {
				width: 100%;
			}
			> img {
				position: absolute;
				right: 0;
				bottom: 100%;
				cursor: pointer;
				width: auto;
			}
			&.inv-det {
				padding: 0;
				margin-bottom: 0;
				background-color: transparent;
				.firm-profile-title {
					padding-left: 12px;
				}
				.firm-profile-input-bottom.row {
					padding-left: 12px;
				}
			}
			&.bilateral {
				border: 6px solid @blue_light;
				padding: 24px 34px;
			}
		}
	}
	@media (max-width: 1600px) {
		padding: 0 40px;
	}
	@media (max-width: 1440px) {
		padding: 0;
	}
	&.dd {
		max-width: 1392px;
		.firm-profile-section-top.dashboard .firm-profile-input > input {
			max-width: 30vw;
		}
	}
	.firm-profile-section-top {
		background-color: @white;
		box-shadow: 0px 3px 60px #f0f0f7;
		border-radius: 30px;
		padding: 16px 24px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 24px;
		.firm-profile-input {
			display: inline-flex;
			align-items: center;
			position: relative;
			padding: 16px 0;
			> img {
				position: absolute;
				right: 0;
				top: -5px;
				cursor: pointer;
			}
			> span {
				font: normal normal 600 22px/32px Poppins;
				color: @blue_light;
				white-space: nowrap;
				margin-right: 12px;
				width: 125px;
				@media (max-width: 1199px) {
					font: normal normal 600 18px/32px Poppins;
				}
			}
			> input {
				width: 300px;
			}
		}
	}
	.firm-profile-input-bottom {
		display: flex;
		flex-direction: column;
		align-items: center;
		.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
			font-size: 14px;
		}
		&.row {
			flex-direction: row;
			align-items: center;
			padding: 24px 0 12px;
			> span {
				width: 250px;
			}
		}
		> div {
			width: 500px;
			position: relative;
			> img {
				position: absolute;
				right: 0;
				bottom: 110%;
				cursor: pointer;
			}
		}
		> span {
			font: normal normal 600 22px/32px Poppins;
			color: @main;
			@media (max-width: 1199px) {
				font: normal normal 600 18px/32px Poppins;
			}
		}
	}
	.firm-profile-title {
		display: flex;
		justify-content: space-between;
		&.match {
			> span {
				font: normal normal 500 18px/32px Poppins;
			}
		}
		&.your-app {
			> span {
				font: normal normal bold 19px/24px Poppins;
			}
		}
		> span {
			font: normal normal 600 22px/32px Poppins;
			color: @main;
			@media (max-width: 1199px) {
				font: normal normal 600 18px/32px Poppins;
			}
		}
		> img {
			cursor: pointer;
		}
	}
	.firm-profile-subtitle {
		font: italic normal normal 13px/32px Poppins;
	}
	.firm-profile-area {
		margin-bottom: 24px;
		textarea.ant-input {
			border-radius: 15px;
			padding: 24px;
		}
		&.error-block {
			textarea.ant-input {
				border-color: #c63009;
			}
		}
		&.bilateral {
			font: normal normal 500 18px/28px Poppins;
			text-align: justify;
		}
	}
	.firm-filter-section {
		background-color: @white;
		box-shadow: 0px 3px 60px #f0f0f7;
		border-radius: 30px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 24px;
		.firm-filter-header {
			width: 100%;
			// border: 1px solid rgba(112, 112, 112, 0.23);
			border-radius: 27px;
			padding: 32px 24px 0 40px;
			img:first-child {
				margin-right: 24px;
			}
			img:last-child {
				margin-left: auto;
				cursor: pointer;
			}
			span {
				font: normal normal bold 24px/32px Poppins;
				color: @main;
			}
		}
		.firm-filter-content {
			width: 100%;
			padding: 24px 40px 40px 88px;
			color: @main;
		}
		.firm-filter-line {
			width: 100%;
			&:not(:last-child) {
				margin-bottom: 24px;
			}
			.firm-filter-left {
				width: 240px;
				margin-right: 40px;
				.firm-filter-item-name {
					height: 40px;
					padding: 0 16px;
					white-space: nowrap;
					box-shadow: 0px 3px 60px #f0f0f7;
					border-radius: 30px;
					font: normal normal 500 18px/24px Poppins;
				}
				.firm-filter-add-spec {
					padding: 0 16px;
					white-space: nowrap;
					font: normal normal 500 14px/24px Poppins;
				}
			}
			.firm-filter-right {
				flex: 1;
				.firm-filter-item-value {
					display: flex;
					flex-wrap: wrap;
					min-height: 50px;
					padding: 0 16px;
					box-shadow: 0px 3px 60px #f0f0f7;
					border-radius: 30px;
					font: normal normal normal 16px/24px Poppins;
				}
				.firm-filter-add-spec-value {
					height: 36px;
					padding: 0 16px;
					box-shadow: 0px 3px 60px #f0f0f7;
					border-radius: 30px;
					font: normal normal normal 16px/24px Poppins;
				}
			}
			.firm-filter-left > span,
			.firm-filter-right > span {
				background: #ebeffc 0% 0% no-repeat padding-box;
				font: normal normal 500 24px/28px Poppins;
				color: @main;
				border-radius: 5px;
				padding: 8px 24px;
				display: inline-flex;
			}
		}
	}
}

/* Spin */

.ant-spin-nested-loading {
	width: 100%;
	height: 100%;
}

.ant-spin-container {
	min-height: 100%;
	overflow: auto;
}

.ant-spin-nested-loading > div > .ant-spin {
	max-height: none;
}

.dtc-loader {
	height: calc(100Vh - 165px);
	.ant-spin-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

/* Buttons */

.ant-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 21px;
	line-height: 32px;
	color: @white;
	border-radius: 40px;
	height: 52px !important;
	border: none;
	@media (max-width: 1199px) {
    height: 32px !important;
		font-size: 16px;
  }
}

.ant-btn.ant-btn-lg {
	height: 60px;
}

.ant-btn.ant-btn-sm {
	height: 40px !important;
}

.ant-btn.actions-btn {
  min-width: 126px;
  height: 30px!important;
  font: normal normal 600 15px Poppins;
}

.ant-btn.btn-primary, .ant-btn.btn-primary:focus {
  background: @blue_light;
	min-width: 172px;
	@media (max-width: 1199px) {
    min-width: 132px;
  }
}

.ant-btn.btn-primary:hover {
	background: #112b89;
}

.ant-btn.btn-primary:active {
	background: #0d1d58;
}

.ant-btn.btn-primary.white,
.ant-btn.btn-primary.white:focus {
	background: @white;
	color: @main;
	border: 1px solid @main;
}

.ant-btn.btn-primary.white:hover {
	background: #112b89;
	color: @white;
}

.ant-btn.btn-primary.white:active {
	background: #0d1d58;
	color: @white;
}

.ant-btn.btn-primary.greyout {
	background: #f5f5f5;
	color: #9fa1a8;
	border: 1px solid #232D53;
	&:hover {
	  background: #f5f5f5;
	  color: #9fa1a8;
	}
}

.ant-btn.btn-primary.white-red, .ant-btn.btn-primary.white-red:focus {
  background: @white;
  color: @main;
  border: 1px solid #F63659;
}

.ant-btn.btn-primary.white-red:hover {
  background: #D12951;
  color: @white;
}

.ant-btn.btn-primary.white-red:active {
  background: #A81B45;
  color: @white;
}

.ant-btn.btn-primary.white-green, .ant-btn.btn-primary.white-green:focus {
  background: @white;
  color: @main;
  border: 1px solid #228B22;
}

.ant-btn.btn-primary.white-green:hover {
  background: #1A711A;
  color: @white;
}

.ant-btn.btn-primary.orange, .ant-btn.btn-primary.orange:focus {
  background: @orange;
  color: @white;
}

.ant-btn.btn-primary.orange,
.ant-btn.btn-primary.orange:focus {
	background: @orange;
	color: @white;
}

.ant-btn.btn-primary.orange:hover {
	background: #db4617;
}

.ant-btn.btn-primary.orange:active {
	background: #c73420;
}

.ant-btn.btn-primary.orange-margin,
.ant-btn.btn-primary.orange-margin:focus {
	background: @white;
	color: @orange;
	border: 3px solid @orange;
}

.ant-btn.btn-primary.orange-margin:hover {
	background: @orange;
	color: @white;
}

.ant-btn.btn-primary.orange-margin:active {
	background: #c73420;
	color: @white;
}

.ant-btn.btn-primary.red,
.ant-btn.btn-primary.red:focus {
	background: #f63659;
	color: @white;
}

.ant-btn.btn-primary.red:hover {
	background: #d12951;
}

.ant-btn.btn-primary.red:active {
	background: #a81b45;
}

.ant-btn-link,
.ant-btn-link:focus {
	color: @blue_light;
	font: normal normal 600 17px/32px Poppins;
}

.ant-btn-link:hover {
	color: #112b89;
}

.ant-btn-link:active {
	color: #0d1d58;
}

.ant-btn.btn-primary[disabled] {
  background: #f5f5f5;
  color: #232D53;
  border: 1px solid #232D53;
  &:hover {
    background: #f5f5f5;
    color: @main;
  }
}

/* Popconfirm */

.ant-popover-title {
	font: normal normal 600 18px/32px Poppins;
	color: @main;
	text-align: center;
	text-decoration: underline;
}

.ant-popover-content {
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.ant-popover-buttons {
	display: flex;
	justify-content: flex-end;
}

.ant-popover-message-title {
	padding-left: 0;
	font: normal normal 600 14px/24px Poppins;
	color: @main;
}

.ant-popover-message > .anticon {
	display: none;
}

.ant-popover-buttons button.ant-btn {
	background-color: #c3cbdb;
	padding: 0 15px;
	border: none;
}

.ant-popover-buttons button.ant-btn:hover,
.ant-popover-buttons button.ant-btn:focus {
	color: #fff;
	background: #8f9ab0;
}

.overview-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 400px;
	word-break: break-word;
	> div {
		width: 100%;
		font: normal normal 600 14px/24px Poppins;
		color: @main;
		margin-bottom: 16px;
	}
}

/* Forms */

.login-form {
	width: 100%;
	button {
		margin: 0 auto;
	}
	.separator {
		position: relative;
		width: 115px;
		height: 3px;
		margin: 14px auto 22px;
		background-color: rgba(35, 45, 83, 0.15);
	}
	.custom-select {
		margin: 0;
	}
	.form-wrapper {
		border: 1px solid #d3cfcf;
		padding: 32px 24px 16px;
		border-radius: 15px;
		margin-bottom: 24px;
		position: relative;
		> svg {
			position: absolute;
			top: 8px;
			right: 8px;
			z-index: 1;
			cursor: pointer;
			&:hover {
				transform: rotate(180deg);
				transition: all 0.4s ease-in-out;
			}
		}
		&:first-child > svg {
			display: none;
		}
	}
	&.share {
		.form-wrapper:first-child > svg {
			display: block;
		}
	}
	.dnc-form-wrapper {
		margin-bottom: 24px;
	}
	.dnc-title {
		display: flex;
		flex-direction: column;
		> div > div {
			height: 42px;
			margin-bottom: 16px;
			display: flex;
			align-items: center;
			font: normal normal 600 18px/24px Poppins;
			color: rgba(35, 45, 83, 0.43);
			img {
				margin-right: 12px;
			}
		}
		> div > img {
			cursor: pointer;
		}
	}
}

.ant-input {
	height: 42px;
	font-family: 'Poppins', sans-serif;
	color: @main;
}

.ant-input-affix-wrapper > input.ant-input {
	height: 32px;
}

.ant-input,
.ant-input-affix-wrapper,
.ant-input-number {
	border: 1px solid @gray_input;
	border-radius: 5px;
	display: flex;
	align-items: center;
}

.ant-input-number {
	width: 120px;
	margin-right: 8px;
	height: 42px;
	&.ec-data {
		width: auto;
		margin-right: 0;
		height: auto;
		margin: 0 16px;
	}
}

.ant-input-number-handler-wrap {
	display: none;
}

.ant-input-prefix {
	margin-right: 10px;
	width: 22px;
	display: flex;
	justify-content: center;
}

.ant-form-item {
	margin-bottom: 12px;
}

.ant-form-vertical .ant-form-item-label {
	padding-bottom: 0;
	font: normal normal 500 14px/21px Poppins;
	color: @main;
}

.ant-input::placeholder,
.ant-input-number-input::placeholder {
	font: normal normal 300 15px/24px Poppins;
	color: rgba(35, 45, 83, 0.43);
	@media (max-width: 1199px) {
    font: normal normal 300 1.1vw/1.25vw Poppins;
  }
}

.target_description .ant-input::placeholder {
	font: normal normal 300 15px/22px Poppins;
}

textarea.ant-input {
	resize: none;
	&.error-input {
		border-color: #c63009;
	}
}

.ant-input[disabled],
.ant-input-affix-wrapper-disabled,
textarea.ant-input[disabled],
.ant-input-number-disabled,
.ant-input-number-disabled .ant-input-number-input {
	background-color: @white;
	cursor: default;
}

.ant-input-search.content-search {
	width: 240px;
	height: 52px;
	background: @white;
	border-radius: 5px;
	margin-right: 24px;
	.ant-input-affix-wrapper {
		height: 52px;
		border-radius: 5px;
		@media (max-width: 1199px) {
			height: 35px;
		}
	}
	.ant-input-group-addon {
		display: none;
	}
	.ant-input::placeholder {
		font: normal normal 300 15px/24px Poppins;
		color: rgba(35, 45, 83, 0.43);
	}
	@media (max-width: 1199px) {
    height: 32px;
  }
}

/* Collapse */

.site-collapse-custom-collapse {
	width: 100%;
	background-color: @white !important;
}

.site-collapse-custom-panel {
  margin-bottom: 26px;
  border: 1px solid rgba(112, 112, 112, 0.23)!important;
  border-radius: 40px !important;
  padding: 0 46px;
	@media (max-width: 1199px) {
    padding: 10px 20px;
  }
  &.error-block {
    border: 1px solid #C63009!important;
  }
  .ant-collapse-header {
    padding: 12px 0 !important;
  }
  .ant-collapse-arrow {
    right: 0 !important;
  }
  .ant-collapse-content {
    color: @main;
  }
  .ant-collapse-content-box {
    padding-left: 50px;
		@media (max-width: 1199px) {
			padding: 0;
		}
  }
  .collapse-content-title {
    font: normal normal normal 15px/24px Poppins;
    margin-bottom: 16px;
		white-space: pre-line;
    &.md {
      font-size: 13px;
      font-weight: 600;
    }
    &.sm {
      font-size: 11px;
      font-weight: 500;
      text-decoration: underline;
    }
  }
  .tab-checkbox-block {
    display: flex;
    align-items: center;
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
    }
    .ant-checkbox-inner::after {
      width: 6px;
      height: 10px;
    }
    > span {
      margin-left: 12px;
    }
  }
  .question-block {
    margin: 48px 0 32px;
    .question-item {
      min-height: 54px;
      background-color: rgba(35, 45, 83, 0.02);
      padding: 12px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      border-radius: 30px;
      .question-text {
        font: normal normal 500 18px/24px Poppins;
        color: @main;
        margin-right: auto;
      }
      .question-action {
        min-width: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 16px;
        img {
          cursor: pointer;
        }
      }
    }
  }
  .radio-group-space {
    display: flex;
    justify-content: center;
    .ant-radio-wrapper {
      margin: 0 24px;
    }
  }
  .collapse-content-radio {
    display: flex;
    margin-top: 40px;
    .radio-content-wrapper {
      width: min-content;
      display: flex;
      align-items: center;
    }
    .radio-group-content {
      display: flex;
      flex-direction: column;
      span {
        font: normal normal 600 18px/24px Poppins;
        white-space: initial;
      }
      span.italic {
        font: italic normal 300 14px/16px Poppins;
      }
    }
    .radio-margin {
      margin-top: 64px;
    }
  }
  .collapse-checkbox-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }
  .collapse-checkbox-title {
    font: normal normal bold 24px/40px Poppins;
    text-align: center;
  }
  .collapse-checkbox-text {
    font: normal normal normal 19px/32px Poppins;
    text-align: center;
  }
  .collapse-checkbox {
    margin: 40px 0 32px;
    width: 100%;
    justify-content: center;
    span {
      font: normal normal bold 24px/24px Poppins;
      margin-left: 10px;
    }
    .ant-radio-wrapper {
      display: inline-flex;
      margin: 0 40px;
    }
  }
  .tabs-content {
    padding-left: 26px;
		@media (max-width: 1199px) {
			padding-left: 0px;
		}
    .tabs-header {
      display: flex;
      align-items: flex-end;
      margin-bottom: 33px;
      > div {
        padding: 0 24px;
        font-size: 36px;
        line-height: 51px;
        font-weight: bold;
        // margin-right: 80px;
				width: 250px;
				@media (max-width: 1199px) {
					width: 210px;
				}
      }
      > span {
        font-size: 15px;
        line-height: 21px;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .checkbox-header {
      height: 72px;
      color: @main;
      font: normal normal bold 14px/24px Poppins;
      margin-left: 26px;
      text-decoration: underline;
      display: flex;
    }
    .ant-checkbox-group {
      width: 100%;
    }
    .ant-checkbox + span {
      display: flex;
      align-items: center;
    }
  }
  .collapse-content-portfolio {
    display: flex;
    align-items: center;
    justify-content: center;
    .portfolio-title {
      font: normal normal 600 24px/44px Poppins;
      white-space: nowrap;
      margin-right: 40px;
    }
    .ant-input {
      width: 582px;
    }
    .ant-btn {
      width: 161px;
      height: 38px !important;
      font: normal normal bold 13px/20px Poppins;
    }
  }
  .divider {
    width: 100%;
    height: 3px;
    background: rgba(35, 45, 83, 0.15);
    border-radius: 8px;
  }
  .add-info-content {
    font-size: 18px;
    font-weight: 600;
    .add-info-title {
      font: normal normal bold 16px/24px Poppins;
      margin-top: 40px;
    }
    .add-info-text {
      font: normal normal normal 15px/24px Poppins;
      margin-bottom: 40px;
    }
    .enterprise-value {
      font-size: 16px;
      font-weight: 300;
      white-space: nowrap;
    }
    .add-info-questions {
      font: normal normal 500 15px/24px Poppins;
      padding-left: 150px;
      .ant-input-number {
        width: 130px;
      }
    }
    .ant-radio-wrapper {
      display: inline-flex;
      align-items: center;
    }
    span {
      font-size: 19px;
      font-weight: 500;
      color: @main;
    }
  }
}

.collapse-header-custom {
	display: flex;
	align-items: center;
	font-size: 19px;
	font-weight: bold;
	color: @main;
	img {
		margin-right: 22px;
	}
	span {
		white-space: nowrap;
	}
}

/* Tabs */

.ant-tabs.ant-tabs-left {
	align-items: baseline;
}

.ant-tabs-left .ant-tabs-nav {
	// min-width: 260px;
	display: flex;
	justify-content: center;
}

.ant-tabs-tab-btn {
	font-size: 28px;
	@media (max-width: 1440px) {
		font-size: 18px;
	}
	@media (max-width: 1199px) {
		font-size: 14px;
	}
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
	padding-left: 64px;
	@media (max-width: 1199px) {
    padding-left: 14px;
    padding-right: 31px;
  }
}

.ant-tabs-left .ant-tabs-tab {
	font-size: 18px;
	font-weight: 500;
	color: @main;
	margin-bottom: 36px !important;
	&:hover {
		color: @main;
		font-weight: bold;
	}
}

.ant-checkbox + span {
	align-self: center;
}

.ant-tabs-left .ant-tabs-tab .tab-checkbox-block {
	font-size: 16px;
	font-weight: 500;
	color: @main;
	&:hover {
		color: @main;
		font-weight: bold;
	}
}
.ant-tabs-left .ant-tabs-tab-active .tab-checkbox-block {
	color: @main;
	font-weight: bold;
}

.ant-tabs-top:not(.focus-manager-tabs) .ant-tabs-tab {
	font: normal normal bold 32px/40px Poppins;
	color: rgba(35, 45, 83, 0.37);
	margin-right: 42px;
	padding-top: 0;
	&.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: @main;
		font-weight: bold;
		border-bottom: 5px solid @orange;
	}
	&:hover {
		color: @main;
	}
	.ant-tabs-tab-btn {
		border-bottom: 5px solid transparent;
	}
}

.ant-tabs-top.focus-manager-tabs .ant-tabs-tab {
	font: normal normal 600 17px/32px Poppins;
	color: rgba(35, 45, 83, 0.5);
	margin-right: 32px;
	padding-top: 0;
	padding-bottom: 0;
	&.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: @main;
		border-bottom: 3px solid @orange;
	}
	&:hover {
		color: @main;
	}
	.ant-tabs-tab-btn {
		border-bottom: 3px solid transparent;
		> span {
			margin-right: 16px;
		}
		> img {
			margin-right: 12px;
		}
	}
}

.ant-tabs-top:not(.focus-manager-tabs) > .ant-tabs-content-holder {
	overflow: auto;
	@media (max-width: 1199px) {
		width: 100%;
  }
}

.ant-tabs-top.deal-deatils-tab > .ant-tabs-content-holder {
	height: calc(100vh - 238px);
	@media (max-width: 1199px) {
		width: 100%;
  }
	.ant-tabs-content {
		height: 100%;
	}
}
.ant-tabs.mandates > .ant-tabs-content-holder {
	width: 100%;
}

.ant-tabs.dashboard-tabs > .ant-tabs-content-holder {
	width: 100%;
}

.ant-tabs.deals-submitted > .ant-tabs-content-holder {
	width: 100%;
}

.ant-tabs-top:not(.focus-manager-tabs) > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
	display: none;
}

.vertical-tab-line {
	position: absolute;
	width: 7px;
	height: 100%;
	left: 0;
	background: rgba(35, 45, 83, 0.15);
	border-radius: 8px;
}

.vertical-line {
	width: 7px;
	background: rgba(35, 45, 83, 0.15);
	border-radius: 8px;
	margin: 0 32px 0 24px;
}

.ant-tabs-left .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: @main;
	font-weight: bold !important;
	text-shadow: none !important;
}

.ant-tabs-left .ant-tabs-tab .ant-tabs-tab-btn {
	font-size: 18px;
}

.ant-tabs-left .ant-tabs-content {
	position: relative;
}

.ant-tabs-ink-bar {
	display: none !important;
}

/* Modals */

.ant-modal-content {
	padding: 40px 56px;
	border-radius: 30px;
}

.ant-modal-body {
	padding: 0 0 40px;
	.modal-title {
		margin-bottom: 40px;
		text-align: center;
		> span {
			font: normal normal normal 24px/32px Poppins;
			color: @main;
			border-bottom: 4px solid @orange;
		}
	}
	.delete-title {
		text-align: center;
		> span {
			font: normal normal 600 24px/32px Poppins;
			color: @main;
			&.delete-item {
				color: @orange;
			}
		}
	}
	.title-block {
		margin-bottom: 24px;
	}
}

.ant-modal-footer {
	display: flex;
	justify-content: center;
	border-top: none;
	> button {
		margin: 0 16px;
	}
}

/* Tags */

.ant-tag.focus-manager-tag {
	height: 32px;
	align-items: center;
	margin-right: 16px;
	margin-bottom: 16px;
	background: #f7f7fb;
	border-radius: 3px;
	font: normal normal normal 15px/32px Poppins;
	color: @main;
}

.ant-tag-close-icon {
	margin-left: 12px;
}

/* Progress */

.progress-bar {
	width: 520px;
}

/* List */

.ant-list {
	width: 100%;
}

/* Checkboxes */

.ant-checkbox-wrapper {
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	color: @main;
	display: inline-flex;
	//align-items: center;
}

.ant-checkbox-inner {
	width: 24px;
	height: 24px;
	background-color: @white;
	border-radius: 5px;
	border: 1px solid @main;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background: @blue;
	border: 1px solid @blue;
	border-radius: 5px;
}

.ant-checkbox-inner::after {
	width: 7px;
	height: 13px;
	left: 25%;
	top: 45%;
}

.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
	visibility: hidden;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
	border: 1px solid @main;
}

/* Radio */

.ant-radio-wrapper {
	font-weight: 500;
	font-size: 18px;
	line-height: 32px;
	color: @main;
}

.ant-radio-inner {
	width: 24px;
	height: 24px;
	background-color: @white;
	border-radius: 5px;
	border: 1px solid @main;
}

.ant-radio-checked .ant-radio-inner {
	background: @blue;
	border: 1px solid @blue;
	border-radius: 5px;
}

.ant-radio-inner::after {
	width: 7px;
	height: 13px;
	left: 65%;
	top: 80%;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	background-color: transparent;
	border-radius: 0;
}

.ant-radio-checked .ant-radio-inner::after {
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
}

.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
	visibility: hidden;
}

.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
	border: 1px solid @main;
}

//select

.ant-select-selector {
	height: 42px;
	border: 1px solid #e5e9ee;
}

.ant-select-selection-placeholder {
	font: normal normal 300 15px/24px Poppins;
	color: rgba(35, 45, 83, 0.43);
	@media (max-width: 1199px) {
    font: normal normal normal 1.12vw/1.25vw Poppins;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	height: 42px;
	display: flex;
	align-items: center;
}

.ant-select.ant-select-multiple .ant-select-selector {
	overflow: auto;
}

.ant-select-multiple .ant-select-selection-item-content {
	white-space: initial;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	background-color: @white;
	cursor: default;
}

.ant-select-dropdown {
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
}

.ant-select-selection-search {
	display: flex;
	align-items: center;
}

.custom-select {
	position: relative;
	display: flex;
	align-items: center;
	margin: 12px 0;
	> span {
		position: absolute;
		left: 11px;
		width: 22px;
		z-index: 1;
	}
	.ant-select-selector {
		padding-left: 43px !important;
		border-radius: 5px !important;
	}
	&.notScaleFontSize{
		.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
			font-size: 14px;
		}
	}
}

//datepicker

.ant-picker {
	border: 1px solid @gray_input;
	border-radius: 5px;
	height: 42px;
	width: 100%;
	&.ec-data {
		padding: 0;
		margin: 0 16px;
		height: 25px;
	}
}

.ant-picker-input > input {
	font-family: 'Poppins', sans-serif;
	color: @main;
	text-align: center;
	&::placeholder {
		font: normal normal 300 15px/24px Poppins;
		color: rgba(35, 45, 83, 0.43);
		@media (max-width: 1199px) {
			font: normal normal normal 1.12vw/1.25vw Poppins;
		}
	}
}

.ant-picker-suffix {
	display: none;
}

.ant-picker-footer-extra {
  padding: 12px;
  text-align: center;
  font: normal normal 600 15px/24px Poppins;
  color: @main;
  > span {
    cursor: pointer;
  }
}

.ant-picker.ph-dark input::placeholder {
  color: @main;
  font-weight: bold;
}

.ant-picker-cell-in-view {
  color: @main !important;
}

.ant-picker-cell {
  color: rgba(35, 45, 83, 0.25);
}

//upload

.ant-upload-list-item-name {
  white-space: initial;
  color: @main;
  font-size: 12px;
}

//dropdown

.custom-dropdown .ant-dropdown-menu-item {
	font: normal normal normal 14px/24px Poppins;
	color: @main;
}

//Breadcrumb

.root-breadcrumb,
.child-breadcrumb,
.ant-breadcrumb-separator {
	font: normal normal 600 28px/40px Poppins;
	color: @white;
	@media (max-width: 1199px) {
   font-size: 24px;
  }
}
.root-breadcrumb {
	text-decoration: underline;
	cursor: pointer;
}

//message

.ant-message-notice {
	margin: 0 8px;
}

// ______________________________________________

.sign-up-step {
	background-color: @gray_bg3;
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 133px 140px 32px;
	@media (max-width: 1366px) {
		padding: 133px 40px 32px;
	}
	&.white {
		background-color: @white;
	}
	&.filters-edit {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 133px 80px 32px;
		&.mandateSharing {
			padding: 72px 140px 24px;
			@media (max-width: 1440px) {
				padding: 72px 30px 24px;
			}
		}
		@media (max-width: 1366px) {
			padding: 133px 40px 32px;
		}
	}
	&.wo-padding {
		padding: 133px 0 32px;
		justify-content: flex-start;
		@media (max-width: 1199px) {
			padding-top: 100px;
		}
		.ant-tabs {
			width: inherit;
			max-width: 1392px;
			@media (max-width: 1440px) {
				max-width: 90vw;
			}
		}
		.ant-tabs > .ant-tabs-nav {
			padding: 0 24px;
		}
		// .ant-tabs-content {
		// 	// justify-content: center;
		// }
		.ant-tabs-tabpane {
			// width: 100%;
		}
	}
	&.dark {
		background-color: rgba(0, 0, 0, 0.6);
		padding-top: 32px;
		> .modal-container {
			box-shadow: 0px 3px 60px rgba(240, 240, 247, 1);
		}
	}
	&.with-bg,
	&.intro-bg {
		background-image: url('./Images/bridge-bg.jpg');
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		&::before {
			content: '';
			background-color: rgba(0, 0, 0, 0.6);
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		> .with-bg-top {
			height: 133px;
			width: 100%;
			background-color: @main;
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			align-items: center;
			> img {
				padding-left: 68px;
				cursor: pointer;
				// width: 11.5vw;
			}
		}
		> .modal-container {
			z-index: 1;
			box-shadow: 0px 3px 60px rgba(240, 240, 247, 1);
		}
	}
	&.without-bg {
		background-image: none;
		padding: 32px;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	&.intro-bg {
		background-image: none;
		position: fixed;
		padding: 32px;
		z-index: 999;
		background-color: transparent;
		top: 0;
		left: 0;
		> .modal-container {
			max-height: calc(100vh - 64px);
			overflow: auto;
			.ant-spin-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				overflow: initial;
			}
		}
	}
	&.with-bg {
    padding-bottom: 0;
		// > .modal-container {
		// 	max-height: calc(100vh - 172px);
		// 	overflow: auto;
		// }
	}
	&.p-a {
		padding: 40px;
		z-index: 1;
	}
	&.step-1 {
		padding-top: 100px;
		@media (max-width: 1199px) {
			padding: 16px;
		}
	}
	.divider {
    width: 100%;
    height: 3px;
    background: rgba(35, 45, 83, 0.15);
    border-radius: 8px;
		margin: 24px auto;
  }
	> img {
		position: absolute;
		top: 32px;
		left: 68px;
		cursor: pointer;
		// width: 11.5vw;
		@media (max-width: 1199px) {
			display: none;
		}
	}
	> .modal-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: @white;
		box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.04);
		padding: 56px 64px;
		border-radius: 30px;
		position: relative;
		> svg {
			position: absolute;
			top: 16px;
			right: 16px;
			z-index: 1;
			cursor: pointer;
			&:hover {
				transform: rotate(180deg);
				transition: all 0.4s ease-in-out;
			}
		}
	}
	> .not-found {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.not-found-404 {
			font: normal normal 600 72px/80px Poppins;
			color: @main;
			border-bottom: 2px solid @orange;
		}
		.not-found-text,
		.not-found-link {
			max-width: 775px;
			font: normal normal normal 18px/36px Poppins;
			color: @main;
			text-align: center;
			> a {
				font: normal normal normal 18px/36px Poppins;
				color: @blue_light;
			}
		}
		.not-found-text {
			margin: 68px 0 40px;
		}
	}
	> .t-cs-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: @white;
		width: 80%;
		height: calc(100Vh - 80px);
		@media (max-width: 1199px) {
			height: calc(100Vh - 32px);
		}
	}
	.space-logo {
		margin-bottom: 75px;
		text-align: center;
		img {
			cursor: pointer;
		}
	}
	.step-number {
		font-size: 36px;
		line-height: 48px;
		font-weight: 600;
		text-align: center;
		&.underline {
			border-bottom: 4px solid @orange;
		}
	}
	.app-form-title {
		display: inline-block;
		margin: 0 auto;
		> div:first-child {
			font: normal normal bold 28px/40px Poppins;
			text-align: center;
		}
	}
	.app-form-text {
		font: normal normal normal 13px/24px Poppins;
		text-align: center;
		margin-bottom: 24px;
	}
	.app-form-quiz {
		border: 1px solid #d3cfcf;
		border-radius: 15px;
		padding: 16px;
		width: 100%;
		label:not(.ant-radio-wrapper) {
			font: normal normal normal 9px/16px Poppins;
			color: @main;
		}
		.ant-radio-wrapper {
			margin-right: 0;
			font: normal normal 500 10px/16px Poppins;
			> span:last-child {
				align-self: center;
			}
		}
		.ant-radio-inner {
			width: 20px;
			height: 20px;
		}
		.ant-radio-inner::after {
			width: 6px;
    	height: 12px;
			top: 85%;
		}
		.ant-form-item {
			width: 100%;
			> .ant-form-item-control:last-child {
				text-align: right;
			}
		}
		.divider {
			width: 100%;
			height: 1px;
			background: rgba(35, 45, 83, 0.15);
		}
	}
	.absolute-header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 133px;
		background-color: @main;
		display: flex;
		align-items: center;
		@media (max-width: 1199px) {
			height: 100px;
		}
		.logo-block {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 68px;
			img {
				width: 11.5vw;
			}
		}
		.title-block {
			display: flex;
			align-items: center;
			padding: 0 80px;
			font: normal normal 600 28px/40px Poppins;
			color: @white;
			text-transform: capitalize;
			&.bottom {
				align-self: flex-end;
				line-height: 28px;
			}
			@media (max-width: 1199px) {
				font-size: 24px;
				&.bottom {
					line-height: 24px;
				}
			}
		}
	}
	.return-block {
		padding: 16px 68px;
		color: @main;
		align-self: flex-start;
		a {
			display: inline-flex;
			align-items: center;
			span {
				font: normal normal 500 14px/24px Poppins;
				text-decoration: underline;
				margin-left: 10px;
				width: 150px;
				@media (max-width: 1199px) {
					font: normal normal 500 1.1vw/1.25vw Poppins;
				}
			}
		}
	}
	.section-content {
		// width: 1392px;
		width: 100%;
		background-color: @white;
		padding: 24px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 32px;
		box-shadow: 0px 3px 60px #f0f0f7;
		border-radius: 30px;
		min-height: 90px;
		font: italic normal 500 16px/24px Poppins;
		color: @main;
    &.no-background {
      background-color: transparent;
    }
	}
	.step-title {
		font-size: 2.56vw;
		line-height: 2.92vw;
		font-weight: bold;
		margin-bottom: 8px;
		text-align: center;
		@media (max-width: 1199px) {
			margin-top: 20px;
		}
		&.intro {
			font: normal normal bold 24px/40px Poppins;
			border-bottom: 4px solid @orange;
		}
		&.sign-up-start {
			font: normal normal bold 22px/32px Poppins;
			margin-bottom: 32px;
		}
		&.invite {
			font: normal normal 500 44px/60px Poppins;
		}
		&.verification {
			font: normal normal 500 24px/50px Poppins;
			margin-bottom: 0;
		}
		&.public {
			font: normal normal 600 49px/64px Poppins;
			margin-bottom: 150px;
		}
		> span {
			font: normal normal 500 16px/27px Poppins;
			display: inline-block;
			margin-bottom: 16px;
		}
    &.edit {
      align-self: flex-start;
      border-bottom: 4px solid @orange;
      margin-bottom: 20px;
    }
	}
	.step-text {
		font-size: 19px;
		line-height: 32px;
		font-weight: 500;
		margin: 16px 0 24px;
		text-align: center;
		white-space: pre-line;
		&.border {
			border: 1px solid @gray_divider;
      border-top-left-radius: 50px 80px;
      border-bottom-left-radius: 50px 80px;
      border-top-right-radius: 50px 80px;
      border-bottom-right-radius: 50px 80px;
      padding: 20px;
		}
		&.update {
			font: normal normal normal 13px/16px Poppins;
			text-align: left;
		}
	}
	.t-cs-agree {
		border: 1px solid #d3cfcf;
		border-radius: 15px;
		padding: 0 24px;
		> div {
			color: @main;
			font: normal normal 600 13px/32px Poppins;
		}
	}
	.step-finish-title {
		font: normal normal bold 22px/24px Poppins;
		text-align: center;
		&.intro {
			font: normal normal 600 22px/32px Poppins;
			border-bottom: 4px solid @orange;
		}
		&.invite {
			font: normal normal 600 32px/42px Poppins;
		}
	}
	.step-finish-text {
		font: normal normal 500 15px/24px Poppins;
		text-align: center;
		margin-bottom: 24px;
		&.intro {
			font: italic normal 300 15px/24px Poppins;
			margin-top: 24px;
		}
		&.invite {
			width: auto;
			font: normal normal 500 24px/32px Poppins;
			margin-bottom: 64px;
		}
	}
	.step-finish-divider {
		width: 100%;
		height: 4px;
		background-color: @orange;
		margin: 8px 0 32px;
		&.intro {
			width: 80%;
		}
	}
	.intro-text {
		font: normal normal 500 16px/24px Poppins;
		margin-bottom: 24px;
		text-align: center;
	}
	.invite-text {
		font: normal normal normal 15px/30px Poppins;
		margin-bottom: 40px;
		text-align: center;
	}
	.logout-text {
		font: normal normal 500 22px/32px Poppins;
		text-align: center;
		margin-bottom: 32px;
	}
	// .onboarding-text {
	//   font: normal normal normal 20px/36px Poppins;
	//   text-align: center;
	//   margin-bottom: 80px;
	// }
	.sign-up-start-text {
		font: normal normal 500 15px/24px Poppins;
		text-align: center;
		> span {
			font: normal normal normal 13px/24px Poppins;
		}
	}
	.sign-up-public {
		font: normal normal normal 25px/32px Poppins;
		text-align: center;
		margin-bottom: 100px;
	}
	.sign-up-start-divider {
		width: 80%;
		height: 3px;
		background: rgba(35, 45, 83, 0.15);
		border-radius: 8px;
		margin-top: 24px;
	}
	.login-title {
		font: normal normal bold 22px/24px Poppins;
		margin-bottom: 24px;
		border-bottom: 4px solid @orange;
		text-align: center;
	}
	.locked-title {
		font: normal normal bold 22px/24px Poppins;
		margin-bottom: 24px;
		border-bottom: 4px solid @orange;
		text-align: center;
	}
	.welcome-title {
		font: normal normal bold 22px/32px Poppins;
		margin-bottom: 24px;
		border-bottom: 4px solid @orange;
		text-align: center;
		width: 200px;
	}
	.welcome-note {
		font: italic normal normal 13px/18px Poppins;
		margin-top: 24px;
		text-align: center;
		border: 1px solid #d3cfcf;
		border-radius: 15px;
		padding: 16px;
	}
	.locked-text {
		font: normal normal 500 15px/24px Poppins;
		text-align: center;
	}
	.logout-action {
		display: flex;
		justify-content: center;
		button {
			width: 91px;
			margin: 0 15px;
			border-radius: e('10px / 20px');
		}
	}
	.terms-conditions {
		width: 100%;
		height: 340px;
		overflow: auto;
		border: 1px solid #d3cfcf;
		border-radius: 15px;
		padding: 16px;
		font-size: 19px;
		line-height: 32px;
		font-weight: 500;
		margin: 8px 0 16px;
		.Terms {
			padding: 0;
			margin: 0;
			max-width: initial;
			h3:last-child {
				margin-bottom: 0;
			}
		}
	}
	.terms-conditions-bottom {
		span {
			font: normal normal 500 10px/16px Poppins;
		}
		&:last-of-type {
			margin: 8px 0 16px;
		}
    &.links {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 24px;
      a {
        font: normal normal 500 10px/16px Poppins;
        text-decoration: underline;
      }
    }
		&.update {
			width: auto;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-top: 16px;
		}
	}
	.lead-investor-bottom {
		font: normal normal 600 15px/24px Poppins;
		text-align: center;
	}
	.logo-text {
		color: @blue_light;
		font: normal normal bold 32px/48px Poppins;
	}
	> .step2-content {
		width: 580px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	> .step-investor-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		.step-title {
			margin-bottom: 0;
		}
		.step-number {
			margin: 16px 0;
		}
	}
	.add-spec {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font: normal normal 600 16px/45px Poppins;
	}
	.error-input {
		border-color: #c63009;
	}
	.btn-footer {
		display: flex;
		justify-content: center;
		align-items: center;
		&.invite {
			margin-top: 16px;
			> button {
				font: normal normal bold 18px/20px Poppins;
			}
		}
		.btn-text-block {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 32px;
			> span {
				font: normal normal normal 13px/16px Poppins;
				margin-top: 12px;
			}
		}
	}
	.invite-form-title {
		font: normal normal bold 15px/23px Poppins;
		margin-bottom: 12px !important;
		color: @main;
	}
}

.withdraw-btn {
	display: flex;
	flex-direction: column;
	align-items: center;
	> button {
		width: 250px;
	}
	> span {
		font: normal normal normal 13px/16px Poppins;
		margin-top: 12px;
	}
}

.deal-top-info {
	font: normal normal bold 19px/24px Poppins;
  max-width: 1392px;
  width: 100%;
  background-color: @white;
  box-shadow: 0px 3px 60px #F0F0F7;
  border-radius: 30px;
  padding: 32px 40px;
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
	@media (max-width: 1440px) {
		padding: 16px 20px;
		font: normal normal bold 1.24vw/1.25vw Poppins;
  }
	@media (max-width: 1199px) {
		justify-content: center;
  }
  .deal-top-info-item {
    display: flex;
    align-items: center;
		padding: 8px 0;
		@media (max-width: 1199px) {
			flex-basis: 100%;
			justify-content: center;
		}
    > span {
			display: flex;
			justify-content: start;
      // font: normal normal bold 19px/24px Poppins;
      color: @main;
      margin-left: 3%;
			width: 180px;
    }
    > div {
      background: #EBEFFC;
      min-height: 42px;
      min-width: 242px;
      border-radius: 20px;
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      // font: normal normal 600 19px/24px Poppins;
      color: @blue_light;
			a {
				font: normal normal 600 19px/24px Poppins;
      	color: @blue_light;
				text-decoration: underline;
				@media (max-width: 1440px) {
					font: normal normal bold 1.34vw/1.25vw Poppins;
				}
			}
    }
  }
}

.withdraw-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  > button {
    width: 250px;
  }
  > span {
    font: normal normal normal 13px/16px Poppins;
    margin-top: 12px;
  }
}

.review-block {
  max-width: 1392px;
  width: 100%;
  background-color: @white;
  box-shadow: 0px 3px 60px #F0F0F7;
  border-radius: 30px;
  padding: 40px;
  margin-bottom: 32px;
	margin-left: auto;
  margin-right: auto;
  // &.mandate {
  //   max-height: 500px;
  //   overflow: auto;
  // }

  &.deal-share-info {
    display: flex;
    justify-content: space-around;
		padding-bottom: 32px;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
			flex: 1;
      > button {
        min-width: 192px;
				margin: 0 48px;
      }
      > span {
        font: normal normal normal 13px/16px Poppins;
				color: @main;
        margin-top: 12px;
      }
    }
  }
  .review-title {
    font: normal normal 600 22px/24px Poppins;
    color: @main;
  }
  .review-info-block {
    display: flex;
		flex-wrap: wrap;
    width: 100%;
    .review-info-left {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0 12px;
    }
    .review-info-right {
      flex: 1;
      margin: 0 12px;
    }
  }
  .sector-add-spec-value {
    min-height: 42px;
    border: 1px solid #E5E9EE;
    border-radius: 5px;
    font: normal normal 300 15px/24px Poppins;
    color: @main;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 16px;
		white-space: pre-line;
    &.question-value {
      font: normal normal 500 16px/24px Poppins;
      color: @main;
      margin: 8px 0;
    }
    &.target {
      font-size: 14px;
      flex-wrap: nowrap;
      justify-content: space-between;
      > span {
        width: 85px;
        text-align: right;
        margin-left: 32px;
        font: normal normal bold 19px/24px Poppins;
        &.red {
          color: #F63659;
        }
      }
    }
  }
  .review-subblock {
    position: relative;
    border: 1px solid #D7D7DA;
    border-radius: 16px;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
		@media (max-width: 1100px) {
			padding: 24px 16px;
		}
    > span {
      position: absolute;
      left: 32px;
      top: 24px;
			@media (max-width: 1100px) {
				left: 12px;
      	top: 24px;
			}
    }
    .sector-title {
      display: flex;
      font: normal normal bold 19px/24px Poppins;
      color: @main;
      margin-left: 40px;
			&.dealSharingReview {
				margin-bottom: 24px;
				@media (max-width: 1199px) {
					margin-bottom: 0;
				}
			}
			&.mandate {
				align-items: center;
				margin-top: 16px;
				@media (max-width: 1199px) {
					// flex-direction: column;
					margin-left: 0;
					align-items: start;
					display: flex;
					flex-wrap: wrap;
				}
			}
      .sector-title-name {
        min-width: 130px;
        display: inline-block;
				&.mandate {
					min-width: 180px;
					&.margin-left {
						@media (max-width: 1199px) {
							padding-left: 40px;
						}
					}
				}
      }
			.sector-title-subname {
				font: normal normal 500 18px/24px Poppins;
				margin-right: 24px;
				&.margin-left {
					@media (max-width: 1199px) {
						padding-left: 38px;
					}
				}
				&.margin-left-2 {
					@media (max-width: 1199px) {
						padding-left: 40px;
					}
				}
			}
      span {
				font: normal normal normal 16px/24px Poppins;
				@media (max-width: 1199px) {
					display: block;
					margin-bottom: 16px;
					// width: 60%;
				}
      }
    }
    .add-spec-block {
      margin-bottom: 24px;
			&.mandate {
				display: flex;
				align-items: center;
				margin-left: auto;
				margin-bottom: 0;
				padding: 5px;
				.sector-add-spec {
					margin-right: 12px;
				}
				.sector-add-spec-value {
					width: 320px;
				}
				@media (max-width: 1199px) {
					align-items: start;
					margin: 10px 0 0 0;
					padding-left: 40px;
				}
			}
    }
		.fin-info-block {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 8px 0;
			> div {
				flex: 1;
				display: flex;
				align-items: center;
			}
		}
    .sector-add-spec {
      font: normal normal 500 15px/24px Poppins;
      color: @main;
    }
    .sector-subtitle {
      font: normal normal 500 16px/24px Poppins;
      color: @main;
      display: flex;
      align-items: center;
      margin: 4px 0 4px 40px;
      &.start {
        align-items: flex-start;
      }
      span {
        font: normal normal normal 15px/24px Poppins;
      }
      .subtitle-range-name {
        min-width: 230px;
				@media (max-width: 1199px) {
					min-width: 200px;
				}
        &.greyborder {
          border: 1px solid @gray_divider;
          padding: 6px;
          border-radius: 4px;
        }
      }
      .subtitle-range-value {
        min-width: 76px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        font: normal normal 300 15px/23px Poppins;
        margin: 0 12px 0 0;
        border: 1px solid #E5E9EE;
        border-radius: 5px;
      }
      .subtitle-range-end {
        font: normal normal 500 16px/25px Poppins;
        white-space: nowrap;
      }
			.subtitle-na {
				width: 175px;
				text-align: center;
			}
			.blue {
				font: normal normal bold 11px/16px Poppins;
				color: @blue_light;
			}
			.md-text {
				font: normal normal 500 14px/16px Poppins;
				color: @main;

			}
    }
  }
  .overview-title {
    display: flex;
    align-items: center;
    margin-left: 32px;
    margin-top: 24px;
    font: normal normal bold 19px/19px Poppins;
    color: @main;
		&.mandate {
			margin-top: 0;
			margin-left: 0;
		}
    &.subtitle {
      font-size: 16px;
      align-self: flex-start;
      margin-top: 14px;
      padding-right: 20px;
    }
    &.smaller {
      font-weight: 400;
      font-size: 15px;
			align-items: baseline;
			.question-text {
				padding-right: 16px;
				word-break: break-word;
			}
    }
		&.mandate-question {
			padding: 12px 0;
		}
    > div {
      min-width: 130px;
      display: inline-block;
    }
    > span {
      width: 40px;
    }
		> img {
			cursor: pointer;
		}
    .overview-value {
      font-weight: 500;
      margin-left: 16px;
    }
  }
	.question-text {
		font-weight: 600;
		margin-right: auto;
	}
	.publish-btn {
		font: normal normal bold 14px/16px Poppins;
		cursor: pointer;
		color: @blue_light;
		margin-right: 16px;
		min-width: auto !important;
		white-space: nowrap;
	}
  .overview-text {
    border: 1px solid #D7D7DA;
    border-radius: 16px;
    padding: 24px 32px;
    margin-top: 12px;
    margin-left: 54px;
    min-height: 72px;
    &.question-text {
      min-height: auto;
    }
    .dnc-user {
      display: flex;
      align-items: center;
      height: 42px;
			margin: 12px 0;
      > span {
        font: normal normal 600 18px/24px Poppins;
        color: rgba(35, 45, 83, 0.43);
        margin-left: 24px;
      }
    }
    .dnc-input {
      min-height: 42px;
			height: auto;
    	word-break: break-word;
      border: 1px solid #E5E9EE;
      border-radius: 5px;
      font: normal normal 300 15px/24px Poppins;
      color: @main;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 8px 16px;
			margin: 12px 0;
      &.area {
        min-height: 70px;
      }
    }
  }
  .area-text {
    margin-top: 12px;
    margin-left: 40px;
    &.exp {
      margin-left: 32px;
    }
    .ant-input {
      border: 1px solid #D7D7DA;
      border-radius: 16px;
      padding: 21px;
      // min-height: 120px;
    }
    &.addinfo{
      margin-left: 0;
			margin-top: 0;
      width: 100%;
    }
  }
  .work-exp-block {
    display: flex;
    margin-top: 24px;
    .experience-list-block,
    .day-rate-block {
      width: 48%;
    }
    .day-rate-block {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .vertical-line {
      margin: 0 auto;
    }
    .list-item {
      border: 1px solid #D7D7DA;
    }
    .experience-title {
      font: normal normal bold 19px/24px Poppins;
      text-align: center;
    }
  }
}

@media (max-width: 1199px) {
	.sign-up-step:not(.without-bg, .filters-edit) {
		padding: 16px;
		// > img {
		// 	display: none;
		// }
		.modal-container {
			// width: 100% !important;
			padding: 32px 16px !important;
		}
		// .step-title,
		// .step-finish-title,
		// .step-finish-title.intro,
		// .login-title {
		// 	margin-bottom: 48px;
		// }
		.step-title.verification {
			font: normal normal bold 28px/40px Poppins;
			width: 80%;
		}
		.login-form {
			width: 80%;
		}
		.with-bg-top {
			display: none;
		}
	}
	.without-bg {
		> img {
			width: 140px;
			left: 32px;
		}
	}
}

@media (max-width: 599px) {
	.sign-up-step {
		padding: 16px;
		&.intro-bg {
			padding: 16px;
			> .modal-container {
				max-height: calc(100vh - 32px);
			}
		}
		&.without-bg {
			padding: 16px;
			> img {
				display: inline;
				width: 76px;
				left: 16px;
				top: 16px;
			}
		}
		> img {
			display: none;
		}
		.modal-container {
			width: 100% !important;
			padding: 16px !important;
			.space-logo {
				margin-bottom: 48px;
				img {
					width: 80%;
				}
			}
		}
		> .not-found {
			.not-found-404 {
				font-size: 44px;
			}
			.not-found-text,
			.not-found-link {
				font-size: 14px;
				> a {
					font-size: 14px;
				}
			}
			.not-found-text {
				margin: 40px 0 24px;
			}
		}
		.step-title,
		.step-finish-title,
		.step-finish-title.intro,
		.login-title {
			margin-bottom: 32px;
			font: normal normal bold 28px/40px Poppins;
		}
		.login-title {
			margin-bottom: 24px !important;
			width: 80%;
		}
		.step-title.verification {
			width: 100%;
		}
		.intro-text,
		.step-finish-text,
		.step-finish-text.intro {
			font: normal normal 500 14px/24px Poppins;
			margin-bottom: 40px;
			width: 100% !important;
		}
		.login-form {
			width: 100%;
			padding: 0 !important;
			.ant-col {
				width: 100%;
			}
		}
		.ant-btn {
			width: 130px !important;
			height: 40px !important;
			font: normal normal bold 14px/24px Poppins;
		}
	}
}

.pa-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pa-message {
	font: normal normal bold 16px/24px Poppins;
	margin: 0 32px;
	color: @blue;
}

.download-btn {
  border: 1px solid #D3CFCF;
  border-radius: 15px;
  margin: 8px 0;
  align-self: flex-end;
	display: flex;
	align-items: center;
	@media (max-width: 1199px) {
		height: 32px;
	}
  &.main {
    display: inline-flex;
    margin: 0;
    align-self: center;
  }
  > a {
    padding: 8px 16px;
    display: flex;
    align-items: center;
		@media (max-width: 1199px) {
			padding: 0 16px;
		}
    > img {
      margin-right: 16px;
			@media (max-width: 1199px) {
				width: 18px;
				margin-right: 8px;
			}
    }
    > span {
      color: @main;
      font: normal normal 600 14px/24px Poppins;
			white-space: nowrap;
    }
  }
}

.error-message {
	color: #c63009;
	font-size: 14px;
}

.direct-link {
	font: normal normal normal 14px/34px Poppins;
	text-decoration: underline;
}

.flex-center-vertical {
	display: flex;
	align-items: center;
}

.flex-center-horizontal {
	display: flex;
	justify-content: center;
}

.flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

.firm-actions {
  display: flex;
  align-items: center;
  .ant-upload-list {
    display: none;
  }
}

.investment-range {
	display: flex;
	align-items: center;
	padding: 16px 0;
	border-bottom: 3px solid rgba(35, 45, 83, 0.15);
	&.base {
		align-items: flex-start;
		//padding-bottom: 0;
		&:last-child {
			border-bottom: none;
		}
	}
  &.noborder {
    border-bottom: none;
    padding: 16px 0 4px;
  }
	.comments-block {
		width: 100%;
		display: flex;
		align-items: center;
		span {
			font: normal normal 600 18px/24px Poppins;
			margin-right: 32px;
		}
	}
	.investment-range-title {
		flex: 1;
		font: normal normal 600 18px/32px Poppins;
		> span {
			font: normal normal normal 14px/32px Poppins;
		}
	}
	.investment-range-value {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		font: normal normal normal 16px/24px Poppins;
		min-height: 50px;
		background-color: @white;
		box-shadow: 0px 3px 60px #f0f0f7;
		border-radius: 30px;
		padding: 16px;
	}
	.investment-range-data {
		display: flex;
		align-items: center;
		flex: 1.5;
		@media (max-width: 1439px) {
			flex-wrap: wrap;
			// > div:not(.ant-input-number) {
			//   width: 100%;
			//   height: 20px;
			// }
		}
		// > div:not(.ant-input-number) {
		//   width: 32px;
		// }
		.investment-range-label {
			display: inline-flex;
			font: normal normal normal 11px/24px Poppins;
			color: rgba(35, 45, 83, 0.43);
			margin: 0 16px;
		}
		.ant-input-number-disabled .ant-input-number-input {
			color: @gray_text;
		}
		> span {
			white-space: nowrap;
			&:first-of-type {
				width: 100px;
				font: normal normal 500 18px/24px Poppins;
			}
			&:last-of-type:not(.percent-span) {
				font: normal normal normal 15px/24px Poppins;
				margin: 0 8px;
				width: 40px;
			}
		}
		.ant-input,
		.ant-input-number {
			width: 76px;
		}
		.ant-input {
			margin-right: 8px;
		}
		.ant-input-number {
			margin-right: 0;
		}
		.na-block {
			display: flex;
			align-items: center;
			> span {
				font: normal normal 500 18px/24px Poppins;
				margin: 0 24px;
			}
		}
	}
	.investment-range-check {
		flex: 1.5;
		.ant-radio-wrapper > span:last-child,
		.ant-checkbox-wrapper {
			font: normal normal 500 15px/24px Poppins;
			.blue {
				font: normal normal bold 11px/16px Poppins;
				color: @blue_light;
			}
		}
	}
	.investment-range-radio {
		flex: 1;
		display: flex;
		align-items: center;
		.ant-row {
			width: 100%;
			align-items: center;
		}
		label {
			margin-right: 32px;
		}
	}
}

.investment-range-up {
	font: normal normal 600 18px/32px Poppins;
	> span {
		font: normal normal normal 14px/32px Poppins;
	}
}

.resize-target {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -999;
}

.grid-container {
	display: flex;
	flex-wrap: wrap;
	.ant-checkbox-wrapper {
		font-weight: 600;
		font-size: 13px;
		line-height: 20px;
	}
	.ant-checkbox-inner {
		width: 18px;
		height: 18px;
	}
	.ant-checkbox-inner::after {
		width: 6px;
		height: 10px;
	}
}

.date-checkbox {
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }
  .ant-checkbox-inner::after {
    width: 6px;
    height: 10px;
  }
}

.return-link {
	a {
		display: inline-flex;
		align-items: center;
		span {
			font: normal normal 500 14px/24px Poppins;
			text-decoration: underline;
			margin-left: 10px;
			width: 200px;
		}
	}
}

.list-text {
	font: normal normal 500 14px/24px Poppins;
	color: @main;
}

.section-title {
	display: inline-block;
	width: fit-content;
	margin: 1.67vw 0px;
	font: normal normal bold 28px/40px Poppins;
	border-bottom: 4px solid @orange;
	color: @main;
	@media (max-width: 1440px) {
		font: normal normal bold 24px/32px Poppins;
	}
	@media (max-width: 1199px) {
		font: normal normal bold 18px/24px Poppins;
	}
	&.bilateral {
		border-bottom: 4px solid @blue_light;
	}
  &.bilateral-mandate {
    border-bottom: 4px solid @blue_light;
    font-size: 24px;
		margin-top: 0;
  }
  &.mandate {
    font-size: 19px;
    width: 100%;
    text-align: center;
    margin-top: 0;
    border-bottom: none;
  }
  &.link {
		width: 100%;
		text-align: center;
    > span:hover {
      cursor: pointer;
      text-decoration: underline;
      color: @blue;
    }
  }
}

.section-title-bottom {
	font: italic normal normal 13px/24px Poppins;
	margin-bottom: 24px;
}

.dtc-form {
	max-width: 1320px;
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	.dtc-title {
		display: inline-block;
		font: normal normal 600 39px/48px Poppins;
		border-bottom: 4px solid @orange;
		margin-bottom: 35px;
	}
	.ant-form {
		width: 100%;
		.input-block {
			.ant-form-item-label {
				display: inline-flex;
				align-items: center;
				> label {
					font: normal normal 500 24px/32px Poppins;
					color: @main;
					@media (max-width: 1199px) {
						font: normal normal 500 1.54vw/1.25vw Poppins;
					}
				}
			}
		}
		.area-block {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			.ant-form-item-label > label {
				font: normal normal 500 14px/24px Poppins;
				color: @main;
			}
			.ant-form-item-control {
				width: 100%;
			}
			.ant-input {
				padding: 20px;
			}
		}
	}
}

.match-company-content {
  display: flex;
  height: 100%;
  .match-company-side {
    flex: 1;
    .match-company-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      span {
        font-size: 32px;
        color: @blue_light;
      }
      img {
        cursor: pointer;
      }
    }
  }
  .match-company-divider {
    height: 100%;
    width: 2px;
    background-color: @main;
    opacity: 0.2;
    margin: 0 24px;
		@media (max-width: 1199px) {
			margin: 0 4px;
		}
  }
}

.share-now-link {
  font: normal normal 600 21px/32px Poppins;
  color: @orange;
	@media (max-width: 1199px) {
		font-size: 16px;
	}
}

.tab-title {
  position: relative;
	// span {
	// 	font-size: 18px;
	// }
  img {
    position: absolute;
    left: 100%;
		@media (max-width: 1440px) {
      width: 18px;
    }
  }
}

.hours_admin_panel {
  width: 330px;
  height: 88px;
  background-color: @white;
  font: normal normal 16px Poppins;
  color: @main;
  display: flex;
  min-height: 80px;
  border-radius: 30px;
  padding: 2px 2%;
  border: 1px solid @blue_light;
	position: relative;
	> img {
		position: absolute;
		cursor: pointer;
		left: 98%;
		bottom: 95%;
	}
  .price-title{
    display: flex;
    width: 90px;
    justify-content: center;
    text-align: center;
  }
  .price {
    display: flex;
    width: 90px;
    justify-content: center;
    font: normal normal 700 20px Poppins;
    color: @main;
    &.blue {
      color: @blue_light;
    }
  }
}

.contract-ends-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  margin-right: 4px;
  margin-bottom: 6px;
  width: 200px;
  height: 32px;
  border: 1px solid @blue_light;
  font: normal normal 12px Poppins;
  border-radius: 20px;
  background-color: @white;
  color: @main;
  .date-contract-ends {
    font-weight: 700;
  }
  .text-label{
    font-weight: 500;
  }
  &.grey {
    background-color: #EBEFFC;
  }
}

.upload-document {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  margin-right: 4px;
  margin-bottom: 6px;
  width: 200px;
  // height: 62px;
  border: 1px solid @blue_light;
  font: normal normal 12px Poppins;
  border-radius: 20px;
  background-color: @white;
  color: @main;
  margin-right: 10px;
  .date-contract-ends {
    font-weight: 700;
  }
}

.documents-admin {
  display: flex;
	align-items: flex-start;
  width: 100%;
  min-height: 94px;
  border: 1px solid rgb(236, 235, 235);
  border-radius: e('35px / 20px');
  background: #fff;
	padding: 4px;
  &.clone {
    height: auto;
    border: none;
    width: 100%;
    overflow: auto;
    flex-wrap: wrap;
    &::-webkit-scrollbar {
      width: 8px;
      height: 80%;
      background: #dfdcdc;
      border-radius: 1ex;
    }
  }
}

.document-admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 90px;
  border: 1px solid @gray_divider;
  border-radius: e('7px / 12px');
  margin: 4px 8px;
  &:hover {
      cursor: pointer;
    }
}

.document-admin-icon {
  width: 30px;
}

.document-admin-title {
  font: normal normal 500 10px Poppins;
  color: @main;
  width: 100%;
  text-align: center;
  margin-top: -2px;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.document-admin-footer {
	font: normal normal 500 10px Poppins;
  color: @main;
	overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
	width: 70px;
	margin: 0 8px 8px 8px;
}
.document-admin-title-date {
  font: normal normal 400 8px Poppins;
  margin-top: -1px;
  text-align: center;
}

.bilaterial {
  display: flex;
  justify-content: flex-start;
}

.grey-wrapper {
  background: #f8f9fa;
  padding: 0 20px 20px 40px;
  border-radius: 30px;
  margin: 10px 0;
}

.collapse-content-area {
  &.inputwrapper {
    width: 30%;
    border: 1px solid #D3CFCF;
    padding: 10px 25px;
    border-radius: 10px;
    background: rgb(255, 255, 255);
    margin-right: 20px;
    margin-top: 20px;
  }
}

.close-document {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font: normal normal 600 16px Poppins;
  color: @main;
  margin-bottom: 6px;
  &.reshare {
	color: @blue;
  }
  &.link {
    width: fit-content;
    border-bottom: 1px solid @main;
    &:hover {
      cursor: pointer;
      color: @red;
      border-bottom: 1px solid @red;
      &.add {
        color: @blue;
      }
      &.delete {
        color: @red;
      }
    }
  }
  &.closed {
    color: @red;
  }
}

.btn-wrapper {
  background: white;
  padding: 20px 30px;
  border: 1px solid @gray_divider;
  border-radius: 30px;
}

.bottom-link {
	color: @main;
	font: normal normal bold 18px/24px Poppins;
	display: block;
	text-align: center;
	div {
		display: inline-block;
		cursor: pointer;
		text-decoration: underline;
	}
}

.section-content-mandate {
	width: fit-content;
	background-color: @white;
	padding: 24px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
	margin: 0 auto 16px;
	box-shadow: 0px 3px 60px #F0F0F7;
	border-radius: 30px;
	@media (max-width: 1199px) {
		padding: 4px;
  }
	.section-count {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 6px 32px;
		color: @main;
		@media (max-width: 1199px) {
			padding: 6px 12px;
		}
		> span {
			font: normal normal 500 1.1vw/1.25vw Poppins;
			margin-right: 16px;
			width: 180px;
			@media (max-width: 1250px) {
				width: 100px;
			}
		}
		> div {
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: 74px;
			height: 40px;
			background-color: #EBEFFC;
			border-radius: 21px;
			font: normal normal 500 1.1vw/1.25vw Poppins;
			color: @blue_light;
			@media (max-width: 1199px) {
				min-width: 54px;
				height: 30px;
			}
		}
	}
}

.question-title {
	max-width: 1392px;
	background-color: @white;
	padding: 16px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 32px;
	box-shadow: 0px 3px 60px #f0f0f7;
	border-radius: e("40px / 5px");
	border: 1px solid #707070;
	min-height: 90px;
	font: normal normal 500 18px/24px Poppins;
	color: @main;
}

.question-block {
	background-color: transparent;
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
	box-shadow: 0px 3px 60px #f0f0f7;
	border-radius: 16px;
	border: 1px solid #D7D7DA;
	min-height: 90px;
	color: @main;
	position: relative;
	&.orange {
		border-color: @orange;
	}
	> span {
		color: @orange;
		font: normal normal bold 13px/16px Poppins;
		position: absolute;
		right: 8px;
		bottom: 100%;
	}
	.question-item {
		font: normal normal 600 15px/24px Poppins;
		width: 90%;
		word-break: break-word;
	}
	textarea.ant-input {
		padding: 16px;
		height: auto;
		border-radius: 15px;
	}
	.question-footer {
		display: flex;
		justify-content: space-between;
		color: @main;
		padding: 0 16px;
		.question-received {
			font: normal normal normal 11px/24px Poppins;
		}
		.question-action {
			font: normal normal bold 13px/24px Poppins;
			cursor: pointer;
		}
	}
}

#disclose-company-form {
	textarea {
		height: 28Vh;
	}
}

.filter-block {
	display: flex;
	margin-bottom: 24px;
	.filter-title {
		margin-right: 16px;
		color: @main;
	}
	.filter-clear {
		margin: 0 40px 0 16px;
		cursor: pointer;
	}
}

.margin-auto {
	margin: 0 auto;
}

.dashboard-tabs {
	margin-top: 1.67vw;
}

.tab-extra-content {
	display: flex;
}

.tab-btn-block {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 12px;
	button {
		margin-left: 24px;
		@media (max-width: 1440px) {
			min-width: 190px;
			height: 40px !important;
			font-size: 18px;
		}
		@media (max-width: 1199px) {
			width: 150px;
			height: 32px !important;
			font-size: 14px;
		}
	}
}

.save-btn {
	text-align: center;
	border: 1px solid #D3CFCF;
  border-radius: 6px;
  margin-left: 6px;
	height: 32px;
	padding-top: 2px;
	width: 100px;
	background-color: #e9ebf0;
	color: @main;
  font: normal normal 600 14px/24px Poppins;
	&:hover {
		cursor: pointer;
	}
}

.ant-select-tree-list-holder-inner {
	font: normal normal normal 0.94vw/1.25vw Poppins;
}

.ant-select-item-option-content {
	font: normal normal normal 0.94vw/1.25vw Poppins;
}

.ant-select-single {
	@media (max-width: 1199px) {
   height: 32px;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	@media (max-width: 1199px) {
		height: 32px;
		font: normal normal normal 0.94vw/1.25vw Poppins;
	 }
}

.dowload-button {
	.ant-btn.btn-primary{
		@media (max-width: 1199px) {
			font: normal normal 500 1.12vw/1.25vw Poppins;
		}
	}
}

.important-block {
	// border-radius: e("25px / 90px");
	border-radius: 10px;
	max-width: 660px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	background: white;
	padding: 20px;
	border: 1px solid rgb(175, 174, 174);
	line-height: 20px;
}

@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;