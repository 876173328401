@import '../../../variables.less';

.header-menu-icon {
  cursor: pointer;
}
.ant-dropdown.custom-menu {
  left: 0 !important;
  right: 0 !important;
  width: 100vw !important;
  padding: 0;
  inset: 71px auto auto auto !important;
  .ant-dropdown-menu {
    // width: 100%;
    // min-height: 256px;
    padding: 0 8px;
    font-size: 14px;
    line-height: 24px;
    background: #FFFFFF;
    box-shadow: 0 3px 15px rgba(22, 21, 21, 0.1);

    .ant-dropdown-menu-item {
      .manrope;
      text-transform: capitalize;
      // font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      color: #000000;
      height: 50px;
      border-bottom: 1px solid @grayBorder;

      // &.align-end {
      //   text-align: end;
      // }

      &:hover {
        opacity: 0.9;
        // color: #E10252;
      }

      &-divider{
        background-color: @grayBorder;
        margin: 10px 0;
      }
    }
  }
}
@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;