@main: #232D53;

@white: #FFFFFF;
@black: #000000;

@gray_text: rgba(35, 45, 83, 0.37);
@gray_border: rgba(112, 112, 112, 0.05);
@gray_shadow: #F0F0F7;
@gray_bg:#EBEFFC;
@gray_bg2: rgba(0, 0, 0, 0.04);
@gray_bg3: #F7F7FB;
@gray_input: #E5E9EE;
@grayBorder: #F3F4F6;

@blue:#1F49E4;
@blue_light: #3680F6;

@orange:#FF6B0F;

@gray_divider: #bec1c4;
@red: #f70808;

//new landing
@main_black: #2D2D2D;
@gray1: #4F4F4F;
@gray3: #828282;
@gray4: #BDBDBD;
@gray5: #E0E0E0;
@gray6: #F2F2F2;
@greenMain: #1C4C5D;
@lightGreen: #26667C;
@greenBorder: #24586A;
@lightBlue2: #DBEAEF;
@lightBlue: #F4FAFC;
@main_orange: #F8941E;

.transition {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .7s;
  transition-property: all;
}

.transition-3 {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: 3s;
  transition-property: all;
}

.transition-05 {
  transition-timing-function: ease;
  transition-duration: .5s;
  transition-property: all;
}

.transition-03 {
  transition-timing-function: ease-in-out;
  transition-duration: .3s;
  transition-property: all;
}

.transition-1 {
  transition-timing-function: ease-in-out;
  transition-duration: 1.0s;
  transition-property: all;
}

.manrope {
  font-family: Manrope, sans-serif;
}
