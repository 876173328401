@import '../../variables.less';

.popup-dialog {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
	min-height: 100vh;
}

.dialog-container {
	background: #ffffff;
	box-shadow: 0 10px 30px rgba(71, 87, 101, 0.3);
	border-radius: 30px;
	animation: 0.5s ease-in-out showpopup-dialog;
	position: relative;
	color: @black;
	overflow: hidden;
	-webkit-box-shadow: -1px 1px 88px -2px rgba(249, 246, 246, 0.521);
	-moz-box-shadow: -1px 1px 88px -2px rgba(249, 246, 246, 0.747);
	box-shadow: -1px 1px 88px -2px rgba(249, 246, 246, 0.836);
}

@keyframes showpopup-dialog {
	0% {
		margin-top: -200%;
	}
	100% {
		margin-left: 0%;
	}
}

.dialog-container .dialog-close-container {
	position: absolute;
	top: 8px;
	right: 8px;
}

.dialog-container .dialog-close-container svg {
	cursor: pointer;
}

.dialog-container .dialog-close-container svg:hover {
	transform: rotate(180deg);
	transition: all 0.4s ease-in-out;
}

// .dialog-header {
//   font-style: normal;
//   font-weight: bold;
//   font-size: 24px;
//   line-height: 29px;
//   display: flex;
//   align-items: center;
//   color: #4E4D54;
//   justify-content: center;
// }

.dialog-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-height: 100vh;
	&.dynamic {
		max-height: calc(100vh - 128px);
		overflow: auto;
	}
	.btn-footer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 56px;
		> button {
			margin: 0 16px;
		}
		.ant-btn.ant-btn-primary.white.btn-primary.flat {
			height: 36px !important;
		}
		.ant-btn.ant-btn-primary.white.btn-primary.welcome {
			height: 34px !important;
			font: normal normal 700 16px Poppins;
		}
	}
	.link-tag {
		font: normal normal 500 10px/16px Poppins;
		color: @main;
	}
	.ant-select-selection-search {
		padding-left: 32px;
	}
	.checkbox-wrapper{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 10px;
		.radio {
			span {
				font: normal normal 500 15px Poppins;
			}
		}
  }
	.deal-text {
		font: normal normal 600 15px/24px Poppins;
		text-align: center;
		color: @main;
		&.md {
			font-size: 13px;
			font-weight: 500;
		}
		&.sm {
			font-size: 10px;
			font-weight: 600;
		}
	}
	.deal-subtext {
		font: italic normal normal 13px/20px Poppins;
		text-align: center;
		color: @main;
	}
	.checkbox-wrap {
		width: 40px;
		display: flex;
		justify-content: center;
	}
	.deal-input {
		display: flex;
		flex-direction: column;
		align-items: center;
		font: normal normal 700 15px/32px Poppins;
		color: @main;
		&.clock {
			margin-left: 6px;
			width: 18px;
		}
		> span {
			.ant-picker.deal-input {
				height: 26px;
				border-radius: 0px;
				border: 2px solid @gray_text;
				padding: 0;
				&.error-input {
					border-color: #C63009;
				}
				&.nbo {
					.ant-picker-suffix {
						left: 90px;
					}
				}
				.ant-picker-suffix {
					display: none;
					position: absolute;
					left: 105px;
				}
			}
		}
	}
}

.ant-picker-dropdown.bottomCenter {
	left: calc(50% - 140px) !important;
}

.dialog-title {
	font: normal normal normal 24px/40px Poppins;
	margin-bottom: 32px;
	border-bottom: 4px solid @orange;
	color: @main;
	&.deal {
		font: normal normal bold 22px/40px Poppins;
	}
	&.bold {
		font-weight: bold;
	}
	&.welcome {
		font: normal normal bold 22px/40px Poppins;
		text-align: center;
		line-height: 140%;
	}
	&.intro {
		font: normal normal bold 22px/40px Poppins;
		text-align: center;
		margin-top: 16px;
	}
}

.dialog-date {
	font: normal normal bold 14px Poppins;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 131px;
	height: 29px;
	background-color: #ebeffc;
	border-radius: 30px;
	color: @blue_light;
	margin: 10px;
}

.dialog-footer {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	padding: 24px 0 0;
}

.dialog-footer button {
	margin: 0 8px;
}

.dialog-info {
	margin-top: 12px;
	width: 100%;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	align-items: center;
	justify-content: center;
	color: #898793;
	width: 390px;
	text-align: center;
}

.dialog-container .popup-title {
	font-family: 'Inter-ExtraBold';
	font-style: normal;
	font-size: 14px;
	line-height: 26px;
	background: @blue;
	color: #ffffff;
	letter-spacing: -0.09px;
	text-align: left;
	padding-left: 16px;
	margin: 0 auto;
}

.dialog-container .item-title {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: @main;
	opacity: 0.6;
	margin-bottom: 16px;
}

.dialog-container .btn-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.add-position {
	font: normal normal 500 14px/21px Poppins;
	text-decoration: underline;
	color: @main;
	cursor: pointer;
}

.area-block {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	> span {
		font: normal normal bold 15px/24px Poppins;
		color: @main;
	}
}

.ant-input.input-textarea {
	height: 97px;
}

.textarea {
	font: normal normal 500 13px Poppins;
	border: 1px solid #d3cfcf;
	border-radius: 16px;
	padding: 20px 10px;
	margin: 20px 0 10px 0;
	line-height: 200%;
}
.choosefield {
	color: @main;
}

.small-text {
	font: normal normal 500 11px Poppins;
  color: @main;
}

.mini-text {
	font: normal normal 600 10px Poppins;
  color: @main;
	text-decoration: underline;
	text-align: center;
}

.checkbox-wrapper {
	display: flex;
	width: 100%;
	justify-content: center;
}

.dealsizerange {
	display: flex;
  flex-direction: column;
	justify-content: center;
	width: 70%;
	&.title {
    flex-direction: row;
		justify-content: space-between;
		width: 100%;
		font: normal normal bold 13px Poppins;
		color: @main;
		.orange {
			color: @orange;
		}
	}
  &.content {
    font: normal normal 500 10px Poppins;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 10px 0px 20px;
		color: @main;
    .blue {
      font: normal normal 700 10px Poppins;
      color: @blue_light;
      margin-left: 24px;
    }
    .checkbox {
      margin-top: 4px;
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
      }
			.ant-checkbox-inner::after {
				width: 6px;
				height: 11px;
			}
    }
  }
  .divider {
    height: 3px;
    width: 100%;
    background-color: rgb(223, 219, 219);
    backdrop-filter: blur(8px);
    margin: 1% 0% 1% 0%;
    border-radius: 2px;
  }
}

.ant-btn.btn-primary.white.btn-primary.upload {
  border: 1px solid rgb(223, 219, 219);
	&:hover {
		border-color: @blue;
		background-color: @white;
	}
}

.ant-input-number.percentage-input {
  width: 53px;
  border-radius: 1px;
  height: 23px;
	&.error-input {
		border-color: #C63009;
	}
}

.upload-block {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.upload-text {
	font: normal normal 600 15px/24px Poppins;
	color: @main;
}

.deal-content-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 80%;
	height: 230px;
	border: 1px solid #707070;
	border-top-left-radius: 20px 40px;
	border-bottom-left-radius: 20px 40px;
	border-top-right-radius: 20px 40px;
	border-bottom-right-radius: 20px 40px;
	margin-top: 40px;
	padding: 4px;
	> span {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

.hours {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 73px;
	height: 37px;
	border: 1px solid #707070;
	border-radius: 30px;
	align-self: center;
	// text-align: center;
	font: normal normal 500 16px Poppins;
	color: @main;
}
.hours-text {
	display: flex;
	align-items: center;
	height: 37px;
	margin-left: 15px;
	line-height: 37px;
	font: normal normal 700 13px Poppins;
	color: @main;
}

.download-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	align-self: center;
	width: 120px;
	height: 78px;
	border: 1px solid #707070;
	border-radius: 10px;
	margin: 15px;
	padding: 10px;
	text-align: center;
	font: normal normal 500 19px Poppins;
	color: @main;
	&.active {
		&:hover {
			cursor: pointer;
		}
	}
	&.red {
		border-color: #c63009;
	}
}

.ant-btn.ant-btn-primary.btn-primary.flat {
	height: 37px !important;
}

.note-text {
	font: normal normal 500 8px Poppins;
	color: @main;
	margin-top: 10px;
}

.ant-input-number.hours {
	width: 73px;
	height: 37px;
	border-radius: 30px;
	border: 1px solid #707070;
	text-align: center;
	.ant-input-number-input {
		padding: 0 8px;
	}
}

.error-input {
	border-color: #c63009;
}

.download-text {
	line-height: 1.7;
}

.ant-input-number.hours {
	.ant-input-number-input {
		text-align: center;
	}
}

.download-link {
	width: 62px;
	height: 46px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid rgba(112, 112, 112, 0.36);
	border-radius: 3px;
	&.upload {
		&:hover {
			cursor: pointer;
		}
	}
	&.red {
		border-color: #c63009;
	}
}

div.ant-upload.ant-upload-select.ant-upload-select-text {
	justify-content: center;
}

.options-wrapper {
	border: 1px solid #707070;
	border-radius: 30px;
	width: 100%;
	padding: 10px;
	margin-top: 20px;
	&.error-block {
		border-color: #c63009;
	}
}

.ant-input-number-input {
	text-align: center;
	// .options {
	//   border-color: #bbb8b8;

	// }
}

// .ant-input-number-input-wrap {
// 	.ant-input-number-input::placeholder {
// 			font: normal normal 700 15px Poppins;
// 			color: @main;
// 	}
// }

.upload-work-done-pop {
	.ant-upload-span {
		margin-top: 10px;
	}
}

.option-input {
	margin-top: 8px;
	width: 70px !important;
	height: 33px !important;
	border-radius: 20px !important;
	border-color: #bbb8b8;
	input {
		background-color: transparent !important;
	}
}

@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;