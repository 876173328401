@import "../../variables.less";

.ant-table {
  font-size: inherit;
  font-family: Poppins;
  color: @main;
  .ant-checkbox-wrapper {
    //display: initial;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: @blue;
    width: 8px;
    height: 8px;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-inner::after {
    width: 6px;
    height: 10px;
  }
  th.ant-table-cell {
    text-align: center;
    font: normal normal 500 0.94vw/1.25vw Poppins;
  }
  td.ant-table-cell {
    @media (max-width: 1199px) {
      padding: 6px 5px;
    }
    @media (max-width: 1440px) {
      padding: 8px 7px;
    }
  }
}

.ant-table.ant-table-default {
  background-color: #fff;
  border: 1px solid #EBE8F7;
  padding-bottom: 8px;
  overflow: auto;
}

.main-table .ant-table.ant-table-default {
  overflow: auto;
}

.main-table {
  .ant-table th.ant-table-cell {
    text-align: left;
  }
}

.spec-table .ant-table.ant-table-default .ant-table-tbody .ant-table-row td:first-child {
  text-align: left;
}

.ant-table.ant-table-default .ant-table-thead th {
  background-color: #F3F5F9;
  border-bottom: 0px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  /* font-size: 14px; */
  color: @main;
}

.ant-table.ant-table-default .ant-table-tbody tr td {
  border-bottom: 0px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  color: #807E8B;
}

.ant-table.ant-table-default .ant-table-tbody .ant-table-row.row-even td {
  background-color: #fff;
}


.ant-table.ant-table-default .ant-table-tbody .ant-table-row.row-odd td {
  background-color: #F7F6FB;
}

.ant-table.ant-table-default .ant-table-tbody .ant-table-row td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: center;
}

.ant-table.ant-table-default .ant-table-tbody .ant-table-row td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ant-table td.cell-center {
  text-align: center;
  padding: 12px 10px;
  @media (max-width: 1199px) {
    padding: 6px 6px;
  }
  @media (max-width: 1440px) {
    padding: 8px 7px;
  }
}
.ant-table td.cell-small {
  width: 0;
}
.ant-table td.cell-left {
  padding-left: 18px;
  @media (max-width: 1199px) {
    padding-left: 8px;
  }
}

.table-column-title{
  display: flex;
  align-items: center;
}

.table-column-title > span {
  margin-right: 16px;
  border-bottom: 1px solid transparent;
  // font: normal normal normal 16px/24px Poppins;
  font: normal normal 500 0.94vw/1.25vw Poppins;
  color: rgba(35,45,83,0.3);
  @media (max-width: 1440px) {
    margin-right: 8px;
  }
  @media (max-width: 1199px) {
    margin-right: 2px;
  }
}

.table-column-title > img {
  margin-right: 8px;
  cursor: pointer;
}

.ant-pagination.ant-table-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  background: none;
}

.ant-pagination-item,
.ant-pagination-item.ant-pagination-item-active {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination-jump-next-custom-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination-item a {
  color: #262F56;
}

.ant-pagination-item a:hover {
  text-decoration: underline;
  color: #262F56 !important;
}

.ant-pagination-item.ant-pagination-item-active a, .ant-pagination-item-link:active {
  color: #262F56 !important;
  opacity: 0.2;
}

.ant-table-pagination.ant-pagination {
  margin: 0;
  padding: 16px 0;
  background: #F3F5F9;
  display: flex;
  align-items: center;
}

.ant-table.ant-table-default .ant-table-tbody tr > td {
  color: @main;
}

.ant-table.ant-table-default .ant-table-tbody tr:hover>td {
  color: rgba(57, 56, 64, 1);
  cursor: pointer;
}

.main-table.share .ant-table-tbody tr > td > a,
.main-table.share .ant-table-tbody tr > td > .link-tag {
  font-size: 14px;
  color:inherit;
  font-weight: inherit;
	@media (max-width: 1440px) {
		font: normal normal 400 0.94vw/1.25vw Poppins;
	}
}

.ant-table .ant-table-tbody tr.new-advisor-row > td {
  background-color: #e5f2ff;
}

.ant-table .ant-table-tbody tr.bold-row > td {
  font-weight: bold;
}

.ant-table .ant-table-tbody tr.red-row > td {
  color: #D7425D;
}

.ant-table .ant-table-tbody tr.red-bold-row > td {
  color: #D7425D;
  font-weight: bold;
}

.ant-table .ant-table-tbody tr.green-row > td {
  color: #228B22;
}

.ant-table.ant-table-default .ant-table-thead th:hover {
  background: #F3F5F9 !important;
}

.ant-table-thead > tr > th {
  background-color: @white;
  font: normal normal normal 16px/24px Poppins;
  color: rgba(35,45,83,0.3);
}

.ant-table-thead > tr > th:hover .table-column-title > span {
  border-bottom: 1px solid @main;
}

.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
  display: none;
}

.ant-table-column-sorters {
  @media (max-width: 1199px) {
    padding: 8px 8px;
  }
}

.ant-table-tbody > tr > td {
  padding: 13px 16px;
  background-color: @white;
  @media (max-width: 1440px) {
    font: normal normal 400 0.94vw/1.25vw Poppins;
  }
}

.ant-table-thead > tr:first-child > th:first-child {
  border-radius: 0;
}

.hidden-column {
  display: none;
}

.bordered {
  border-radius: 45px 0 0 45px;
}

.red-cell {
  color: #C63009;
}

.blue-cell {
  color: @blue_light;
}

.deal-status {
  background-color: #EBEFFC;
  border-radius: 20px;
  color: @blue_light;
  height: 2.18vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal bold 0.94vw/1.25vw Poppins;
  padding: 8px;
  white-space: nowrap;
  min-width: 7.8vw;
  &.orange {
    background-color: #ffd0a63b;
    color: @orange;
  }
  &.mandate {
    width: 30%;
    margin: 0 auto;
  }
  @media (max-width: 1199px) {
    padding: 8px 0.9vw;
  }
}

.deal-action {
  position: relative;
  display: inline-block;
  > img {
    position: absolute;
    left: calc(100% - 15px);
    bottom: 100%;
    @media (max-width: 1440px) {
      width: 18px;
    }
  }
  .ant-btn {
    font-size: 0.94vw;
    font-weight: bold;
    min-width: 10vw !important;
    //width: 12vw;
    height: 2.7vw !important;
    margin: 0 auto;
  }
}

.deal-table {
  &.exp {
    .ant-table-tbody > tr > td:first-child {
      max-width: 130px;
    }
  }
  &.actions {
    .ant-table-thead > tr > th {
      font: normal normal bold 16px/24px Poppins;
    }
    .ant-table-tbody > tr {
      font: normal normal normal 16px/24px Poppins;
    }
  }
  &.no-vw {
    .ant-table-thead > tr > th {
      font: normal normal bold 18px/24px Poppins;
    }
    .ant-table-tbody > tr {
      font: normal normal normal 18px/24px Poppins;
    }
    .deal-status {
      height: 42px;
      font: normal normal bold 18px/24px Poppins;
      padding: 8px 32px;
    }
    .deal-action .ant-btn {
      font-size: 18px;
      height: 52px !important;
    }
    @media (max-width: 1440px) {
      .ant-table-thead > tr > th {
        font: normal normal bold 16px/20px Poppins;
      }
      .ant-table-tbody > tr {
        font: normal normal normal 16px/20px Poppins;
      }
      .deal-status {
        height: 36px;
        font: normal normal bold 16px/20px Poppins;
        padding: 6px 24px;
      }
      .deal-action .ant-btn {
        font-size: 16px;
        height: 36px !important;
      }
    }
    @media (max-width: 1199px) {
      .ant-table-thead > tr > th {
        font: normal normal bold 14px/18px Poppins;
      }
      .ant-table-tbody > tr {
        font: normal normal normal 14px/18px Poppins;
      }
      .deal-status {
        height: 30px;
        // font: normal normal bold 14px/18px Poppins;
        font: normal normal bold 0.94vw/1.25vw Poppins;
        padding: 4px 16px;
      }
      .deal-action .ant-btn {
        font-size: 14px;
        height: 30px !important;
      }
    }
  }
  .ant-table {
    background-color: transparent;
  }
  .ant-table-thead > tr > th {
    background-color: #F7F7FB;
    font: normal normal bold 0.94vw/1.25vw Poppins;
    color: @main;
    border-bottom: none;
    @media (max-width: 1199px) {
      padding: 4px 8px;
    }
  }
  .ant-table-tbody > tr {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    font: normal normal 500 0.94vw/1.25vw Poppins;
    color: @main;
    > td {
      border-bottom: 5px solid #F7F7FB;
      border-top: 5px solid #F7F7FB;
    }
  }
  .ant-table-tbody > tr > td:first-child {
    border-radius: 30px 0 0 30px;
  }
  .ant-table-tbody > tr > td:last-child {
    border-radius: 0 30px 30px 0;
  }
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder > td {
    border-radius: 30px;
  }
  .ant-table-tbody {
    > tr.exp-row-action {
      &.active {
        &:hover {
          td {
            background-color: #fff;
          }
        }
        td {
          border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
    > tr.exp-row {
      &:hover {
        td {
          background-color: #fff;
        }
      }
      td {
        border-radius: 0 0 30px 30px;
        padding: 16px 10vw;
        color: @main;
        border-top: none;
      }
      .exp-content {
        display: flex;
        flex-direction: column;
        .exp-content-title {
          font: normal normal bold 1.1vw/1.25vw Poppins;
          text-align: center;
          text-decoration: underline;
          margin-bottom: 16px;
        }
        > div {
          word-break: break-word;
          margin-bottom: 16px;
          line-height: 24px;
          border: 1px solid #D7D7DA;
          border-radius: 16px;
          padding: 16px;
        }
        .ant-btn {
          font-size: 0.94vw;
          font-weight: bold;
          min-width: 10vw !important;
          //width: 12vw;
          height: 2.7vw !important;
          align-self: center;
        }
      }
    }
  }
}

@media (max-width: 1151px) {
  .ant-pagination.ant-table-pagination {
    justify-content: center;
    padding-right: 16px !important;
  }
  .main-table .ant-table.ant-table-default {
    height: calc(100vh - 136px);
  }
  .main-table.rates-local-table .ant-table.ant-table-default {
    height: calc(100vh - 192px);
  }
  .main-table.rates-ocean-table .ant-table.ant-table-default {
    height: calc(100vh - 256px);
  }
  .main-table.auto .ant-table.ant-table-default {
    height: calc(100vh - 208px);
  }
}

@media (max-width: 767px) {
  .ant-pagination-item,
  .ant-pagination-jump-next {
    display: none;
  }
  .ant-pagination-item.ant-pagination-item-active {
    display: inline-block;
  }
  .ant-pagination-item.ant-pagination-item-active a, .ant-pagination-item-link:active {
    opacity: 1;
  }
}

@media (max-width: 374px) {
  .ant-pagination.ant-table-pagination{
    padding-right: 0 !important;
  }
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;