.main-slider-wrapper {
  margin: 50px auto 0;
  width: 80%;
  @media (max-width: 950px) {
    width: 100%;
  }
}

.firms-carousel-item-wrapper {

  .carousel-item {
    height: 80px;
    padding: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > img {
      width: 127px;
    }
    @media (max-width: 800px) {
      > img {
        width: 100px;
      }
    }
    @media (max-width: 576px) {
      height: 24px;
      padding: 0px;
      > img {
        width: 70px;
      }
    }
  }
}
@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;