@import '../../variables.less';

.ant-btn {

  &.landing {
    box-shadow: none;
    font-family: Manrope, sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    height: 44px !important;
    padding: 0 20px;

    &.ant-btn-default {
      border: 1px solid @gray5 !important;
      color: @main_black !important;
      &:hover {
        border-color: @lightGreen !important;
        // color: @Sales500 !important;
      }
    }

    &.ant-btn-primary {
      // &:disabled {
      // }
      &.primary-green {
        // height: 44px !important;
        background: @greenMain;
        // color: black;
        &:hover {
          background-color: @lightGreen !important;
          // color: @white!important;
          // border-color: @mainOrangeHover;
        }
      }
      &.h-50 {
        height: 50px !important;
      }
    }

    .button-text-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .transition;

      .button-arrow {
        .transition;
        path {
          fill: @white;
        }
      }
      &:hover {
        .button-arrow  {
          transform: rotate(45deg);
        }
      }
    }


  }


  // &.ant-btn-link {
  //   color: @primary;
  //   padding: 0;
  //   &:hover {
  //     // color: @primaryHover !important;
  //   }
  // }

}

@primary-color: #81CDE9;@link-color: #1FCB8A;@success-color: #D3FFEA;@warning-color: #FFF9F0;@error-color: #C63009;@font-size-base: 14px;@heading-color: #393840;@text-color: #393840;@text-color-secondary: #393840;@disabled-color: rga(137,135,147,0.3);@border-radius-base: 6.93366px;@border-color-base: trancparent;@box-shadow-base: none;